import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";

const Dashboard = (props) => {
  const history = useHistory();
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    history.push('/typeManagement');
  }, [])

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">Create Entity Attribute</h5>
          </div>
          <div className="modal-body">
            <form className="french-form" action="entityForm.php" method="POST">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <input type="text" name="entityname" placeholder="Entity Name" className="form-control entity-form" id="entityname" />
                </div>
                <div className="col-md-12 mb-3">
                  <textarea rows={5} type="email" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                </div>
                <div className="col-md-6 mb-3">
                  <input type="text" name="Issue" placeholder="Issue key" className="form-control entity-form" id="Issue" />
                </div>
                <div className="col-md-6 mb-3">
                  <select className="form-select entity-form" aria-label="parententity-label" defaultValue="2">
                    <option value="">Parent entity types</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="cancle_btn_" onClick={() => { setModalStatus(!modalStatus) }}>Cancel</button>
            <button type="submit" name="submit" className="info_btn_">Submit</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Timely Hire</h2>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              More
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMoremenu">
              <li><a className="dropdown-item" onClick={() => { setModalStatus(!modalStatus) }}>Add</a></li>
              <li><a className="dropdown-item">Actives</a></li>
              <li><a className="dropdown-item">All</a></li>
              <li><a className="dropdown-item">Tops</a></li>
              <li><a className="dropdown-item">With Perspective</a></li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Software Developer</td>
                <td>giacomo@yopmail.com</td>
                <td>1</td>
                <td>String</td>
                <td className="dropdown">
                  <a className="dropdown-toggle heading_links_" id="dropdownMenuButton" data-bs-toggle="dropdown">
                    <span className="active-txt"> Active <i className="far fa-angle-down"></i></span>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMoremenu">
                    <li><a className="dropdown-item" href="#">Add</a></li>
                    <li><a className="dropdown-item" href="#">Actives</a></li>
                    <li><a className="dropdown-item" href="#">All</a></li>
                    <li><a className="dropdown-item" href="#">Tops</a></li>
                    <li><a className="dropdown-item" href="#">With Perspective</a></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Software Developer</td>
                <td>giacomo@yopmail.com</td>
                <td>1</td>
                <td>String</td>
                <td className="dropdown">
                  <a className="dropdown-toggle heading_links_" id="dropdownMenuButton" data-bs-toggle="dropdown">
                    <span className="active-txt"> Active <i className="far fa-angle-down"></i></span>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMoremenu">
                    <li><a className="dropdown-item" href="#">Add</a></li>
                    <li><a className="dropdown-item" href="#">Actives</a></li>
                    <li><a className="dropdown-item" href="#">All</a></li>
                    <li><a className="dropdown-item" href="#">Tops</a></li>
                    <li><a className="dropdown-item" href="#">With Perspective</a></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <!-- End Tables --> */}
      </div>

      {/* <!-- Start Contact-Form Modal --> */}
      <CustomModal
        modalIsOpen={modalStatus}
        // closeModal={() => setIsOpen(false)}
        child={modalContent()}
      />
      {/* <!-- End Contact-Form Modal --> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
