import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import Switch from "react-switch";
import { getFormatedDate } from "../../../utilities/helpers";
import { bindActionCreators } from "redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { activateUserTypeCategory, addUserTypeCategory, deactivateUserTypeCategory, editUserTypeCategory, getOneUserTypeCategory, getUserTypeCategory, updateOpenUserProfileUserTypeCategory } from "../../actions/user";
import { getUserType } from "../../actions/typeManagement/userType";

const UserTypeCategory = (props) => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()

    const initField = {
        "description": "",
        "name": "",
        userTypeId: parseInt(id)
    }

    const [loading, setLoading] = useState(false);
    const [isGettingField, setIsGettingField] = useState(false);
    const [updatingList, setUpdatingList] = useState(false);
    const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
    const [switch1Loading, setSwitch1Loading] = useState({ status: false, id: null });
    const [flag, setFlag] = useState("actives");
    const [userTypeCategory, setUserTypeCategory] = useState([]);
    const [modal, setModal] = useState({ status: false, type: 'Add' });
    const [form, setForm] = useState(initField);
    const [UserType, setUserType] = useState([]);

    const onChangeField = (event) => {
        const {
            target: { value, name },
        } = event;
        setForm({ ...form, [name]: value.trimStart() })
    }

    const getUserTypeCategory = (flag, noLoad) => {
        if (!noLoad) {
            setLoading(true);
        }
        props.getUserTypeCategory({ flag, id }, res => {
            setSwitchLoading({ status: false, id: null });
            setSwitch1Loading({ status: false, id: null });
            setLoading(false);
            if (res.status) {
                let data = res.data;
                let sortedData = data && data.length > 0 ? data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
                setUserTypeCategory(sortedData);
                setFlag(flag);
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
            }
        },
            err => {
                toast.error("Something went wrong!");
                setLoading(false);
                setSwitchLoading({ status: false, id: null });
            })
    }

    const addUserTypeCategory = (data) => {
        setUpdatingList(true);
        props.addUserTypeCategory(data, res => {
            setUpdatingList(false);
            if (res.status) {
                getUserTypeCategory(flag);
                setForm(initField);
                setModal({ status: !modal.status, type: 'Add' });
                toast.success("User Type Category added successfully");
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
            }
        },
            err => {
                toast.error("Something went wrong!");
                setUpdatingList(false);
            })
    }

    const editUserTypeCategory = (data) => {
        setUpdatingList(true);
        props.editUserTypeCategory(data, res => {
            setUpdatingList(false);
            setSwitch1Loading({ status: false, id: null });
            if (res.status) {
                setForm(initField);
                getUserTypeCategory(flag, true);
                toast.success("User Type Category updated successfully");
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
                setSwitch1Loading({ status: false, id: null });
            }
        },
            err => {
                toast.error("Something went wrong!");
                setUpdatingList(false);
            })
    }

    const updateOpenUserProfileUserTypeCategory = (data) => {
        setSwitch1Loading({ status: true, id: data.id });
        props.updateOpenUserProfileUserTypeCategory(data, res => {
            setSwitch1Loading({ status: false, id: null });
            if (res.status) {
                getUserTypeCategory(flag, true);
                toast.success("User Type Category updated successfully");
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
                setSwitch1Loading({ status: false, id: null });
            }
        },
            err => {
                toast.error("Something went wrong!");

            })
    }

    const activateUserTypeCategory = (data) => {
        setSwitchLoading({ status: true, id: data.id });
        props.activateUserTypeCategory(data, res => {
            setSwitchLoading({ status: false, id: null });
            if (res.status) {
                getUserTypeCategory(flag, true);
                toast.success("Activated successfully");
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
                setSwitchLoading({ status: false, id: null });
            }
        },
            err => {
                toast.error("Something went wrong!");
                setSwitchLoading({ status: false, id: null });
            })
    }

    const deactivateUserTypeCategory = (data) => {
        setSwitchLoading({ status: true, id: data.id });
        props.deactivateUserTypeCategory(data, res => {
            setSwitchLoading({ status: false, id: null });
            if (res.status) {
                getUserTypeCategory(flag, true);
                toast.success("Deactivated successfully");
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
                setSwitchLoading({ status: false, id: null });
            }
        },
            err => {
                toast.error("Something went wrong!");
                setSwitchLoading({ status: false, id: null });
            })
    }

    const getUserType = () => {
        props.getUserType({ flag: 'actives' }, res => {
            if (res.status) {
                let data = res.data;
                let sortedData = data && data.length > 0 ? data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
                setUserType(sortedData);
            }
        })
    }

    const getOneUserTypeCategory = (id) => {
        setIsGettingField(true);
        setModal({ status: !modal.status, type: 'Edit' });
        getUserType();
        props.getOneUserTypeCategory(id, res => {
            setIsGettingField(false);
            if (res.status) {
                setForm(res.data);
            }
            else {
                let message = "Something went wrong!"
                if (res.message) {
                    message = res.message;
                }
                toast.error(message);
            }
        },
            err => {
                toast.error("Something went wrong!");
                setIsGettingField(false);
            })
    }

    useEffect(() => {
        getUserTypeCategory(flag);
    }, [])

    const onSubmitForm = () => {
        if (!form.name) {
            toast.error('Please enter name');
            return;
        } else if (!form.description) {
            toast.error('Please enter description');
            return;
        }
        if (modal.type === 'Add') {
            addUserTypeCategory(form);
        } else if (modal.type === 'Edit') {
            editUserTypeCategory({ id: form.id, description: form.description, name: form.name, userTypeId: form.userTypeId });
        }
    }

    const onCancel = () => {
        setModal({ ...modal, status: !modal.status });
        setForm(initField);
    }

    const modalContent = () => {
        return (
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} {location.state.name} Category</h5>
                    </div>
                    {isGettingField && !form.name ?
                        <div className="text-center m-5">
                            <span className="spinner-border spinner-border-sm"></span>
                        </div>
                        :
                        <>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Name" className="form-control entity-form" id="entityname" />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <textarea rows={5} value={form.description} onChange={onChangeField} required type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                                    </div>
                                    {modal.type === "Edit" && <div className="col-md-12 mb-3">
                                        <select value={form.userTypeId} onChange={onChangeField} required name="userTypeId" placeholder="User Type" className="form-control entity-form" >
                                            {UserType.length && UserType.map((item) => <option value={item.id}>{item.name}</option>)}
                                        </select>
                                    </div>}
                                </div>
                            </div>

                            <div className="modal-footer">
                                {!updatingList && modal.status ?
                                    <div className="mt-2">
                                        <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                                        <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                                    </div>
                                    :
                                    <span className="spinner-border spinner-border-sm"></span>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }

    let filteredBy = "All";
    switch (flag) {
        case "actives":
            filteredBy = "Actives";
            break;

        case "all":
            filteredBy = "All";
            break;

        case "inactives":
            filteredBy = "In-Actives";
            break;

        default:
            filteredBy = "All";
            break;
    }

    return (
        <>
            <div className="content_area me-0 me-xxl-5">
                <div className="header-title">
                    <h2 className="heading-text">{location.state.name} Category ({filteredBy})</h2>
                    <div className="dropdown more-dropdown">
                        <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
                            Filter
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
                            </li>
                            <li onClick={() => { getUserTypeCategory('actives') }} className={flag === 'actives' ? "active" : ""}>
                                <a className="dropdown-item">Actives</a>
                            </li>
                            <li onClick={() => { getUserTypeCategory('all') }} className={flag === 'all' ? "active" : ""}>
                                <a className="dropdown-item">All</a>
                            </li>
                            <li onClick={() => { getUserTypeCategory('inactives') }} className={flag === 'inactives' ? "active" : ""}>
                                <a className="dropdown-item">In-actives</a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- Start Tables --> */}
                <div className="timelyHire-tables table-responsive">
                    <table className="table registrations_table">
                        <thead>
                            <tr role="row">
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Status</th>
                                <th scope="col">In account of</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ?
                                <>
                                    {userTypeCategory && userTypeCategory.length > 0 ?
                                        <>
                                            {userTypeCategory.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.name ? item.name : "N/A"}</td>
                                                        <td title={item.description}>
                                                            {item.description ?
                                                                item.description.length > 20 ? item.description.substring(0, 20) + ' . . .' : item.description
                                                                :
                                                                "N/A"
                                                            }
                                                        </td>
                                                        <td>
                                                            {switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                                                <Switch
                                                                    onChange={() => item.active === 1 ? deactivateUserTypeCategory({ id: item.id }) : activateUserTypeCategory({ id: item.id })}
                                                                    checked={item.active === 1}
                                                                    className="react-switch"
                                                                    onColor="#b7115d"
                                                                />}
                                                        </td>
                                                        <td>
                                                            {switch1Loading.status && item.id === switch1Loading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                                                <Switch
                                                                    onChange={() => updateOpenUserProfileUserTypeCategory({ id: item.id, value: item.isOpenUserProfile === 1 ? 0 : 1 }, true)}
                                                                    checked={item.isOpenUserProfile === 1}
                                                                    className="react-switch"
                                                                    onColor="#b7115d"
                                                                />}
                                                        </td>
                                                        <td>
                                                            {item.createdDate ?
                                                                <>
                                                                    {getFormatedDate(item.createdDate, 'MM-DD-YYYY')}
                                                                    <br></br>
                                                                    {getFormatedDate(item.createdDate, 'h:mm A')}
                                                                </>

                                                                : "N/A"
                                                            }
                                                        </td>
                                                        <td className="table-action">
                                                            <a onClick={() => { getOneUserTypeCategory(item.id) }}>
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                                    }
                                </>
                                :
                                <tr className="text-center">
                                    <td colSpan={5} className="p-5">
                                        <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <CustomModal
                    modalIsOpen={modal.status}
                    child={modalContent()}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    getUserTypeCategory: bindActionCreators(getUserTypeCategory, dispatch),
    editUserTypeCategory: bindActionCreators(editUserTypeCategory, dispatch),
    activateUserTypeCategory: bindActionCreators(activateUserTypeCategory, dispatch),
    deactivateUserTypeCategory: bindActionCreators(deactivateUserTypeCategory, dispatch),
    addUserTypeCategory: bindActionCreators(addUserTypeCategory, dispatch),
    getOneUserTypeCategory: bindActionCreators(getOneUserTypeCategory, dispatch),
    updateOpenUserProfileUserTypeCategory: bindActionCreators(updateOpenUserProfileUserTypeCategory, dispatch),
    getUserType: bindActionCreators(getUserType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTypeCategory);
