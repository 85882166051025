import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import { getFormatedDate } from "../../../utilities/helpers";
import { activateUserInformation, addUserInformation, deactivateUserInformation, editUserInformation, getOneUserInformation, getUserInformation, validateUserInformation, verifyUserInformation } from "../../actions/user/userInformations";
import CustomModal from "../../components/shared/CustomModal";

const UsersInformations = (props) => {
  const location = useLocation();
  const { name, userIdHash } = location.state;

  const initField = {
    "address": "",
    "address1": "",
    "city": "",
    "country": "",
    "entityName": "",
    "firstName": "",
    "lastName": "",
    "middleName": "",
    "phoneNumber": "",
    "state": "",
    "unitId": "",
    "uploadDirectory": "",
    "zipCode": ""
  }

  const [loading, setLoading] = useState(false);
  const [isGettingFields, setGettingFields] = useState(false);
  const [isUpdatingList, setisUpdatingList] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [switch2Loading, setSwitch2Loading] = useState({ status: false, id: null });
  const [switch3Loading, setSwitch3Loading] = useState({ status: false, id: null });

  const [flag, setFlag] = useState("actives");
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [userInformation, setUserInformation] = useState([]);
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getUserInformation = (flag, noLoad) => {
    setFlag(flag);
    if (!noLoad) {
      setLoading(true);
    }
    props.getUserInformation({ flag, userIdHash }, res => {
      setSwitchLoading({ status: false, id: null });
      setSwitch2Loading({ status: false, id: null });
      setSwitch3Loading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        setUserInformation(res.data);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        //   toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }


  const getOneUserInformation = (id) => {
    setGettingFields(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneUserInformation({ userInformationId: id, userIdHash }, res => {
      setGettingFields(false);
      if (res.status) {
        setForm(res.data);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setGettingFields(false);
      })
  }

  const activateUserInformation = (data) => {
    setSwitchLoading({ status: true, id: data.userInformationId });
    props.activateUserInformation(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getUserInformation(flag, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateUserInformation = (data) => {
    setSwitchLoading({ status: true, id: data.userInformationId });
    props.deactivateUserInformation(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getUserInformation(flag, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const verifyUserInformation = (data) => {
    setSwitch2Loading({ status: true, id: data.userInformationId });
    props.verifyUserInformation(data, res => {
      setSwitch2Loading({ status: false, id: null });
      if (res.status) {
        getUserInformation(flag, true);
        toast.success("Verified successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitch2Loading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitch2Loading({ status: false, id: null });
      })
  }

  const validateUserInformation = (data) => {
    setSwitch3Loading({ status: true, id: data.userInformationId });
    props.validateUserInformation(data, res => {
      setSwitch3Loading({ status: false, id: null });
      if (res.status) {
        getUserInformation(flag, true);
        toast.success("Validated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitch3Loading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitch3Loading({ status: false, id: null });
      })
  }

  const addUserInformation = (data) => {
    setisUpdatingList(true);
    props.addUserInformation({ userIdHash, data }, res => {
      setisUpdatingList(false);
      if (res.status) {
        getUserInformation(flag);
        setForm(initField);
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("User Information added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setisUpdatingList(false);
      })
  }

  const editUserInformation = (data) => {
    setisUpdatingList(true);
    props.editUserInformation({ userIdHash, data }, res => {
      setisUpdatingList(false);
      if (res.status) {
        getUserInformation(flag);
        setForm(initField);
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("User Information updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setisUpdatingList(false);
      })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    getUserInformation(flag);
  }, []);

  const onSubmitForm = () => {
    if (modal.type === 'Add') {
      addUserInformation(form);
    } else if (modal.type === 'Edit') {
      editUserInformation({ id: form.id, ...form });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
  }

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} Users Information</h5>
          </div>
          {isGettingFields ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="label">First Name</label>
                    <input value={form.firstName} onChange={onChangeField} required type="text" name="firstName" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Last Name</label>
                    <input value={form.lastName} onChange={onChangeField} required type="text" name="lastName" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Business Name</label>
                    <input value={form.entityName} onChange={onChangeField} required type="text" name="entityName" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Phone Number</label>
                    <input value={form.phoneNumber} onChange={onChangeField} required type="text" name="phoneNumber" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Address Line 1</label>
                    <input value={form.address} onChange={onChangeField} required type="text" name="address" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Address Line 2</label>
                    <input value={form.address1} onChange={onChangeField} required type="text" name="address1" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">City</label>
                    <input value={form.city} onChange={onChangeField} required type="text" name="city" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Country</label>
                    <input value={form.country} onChange={onChangeField} required type="text" name="country" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">State</label>
                    <input value={form.state} onChange={onChangeField} required type="text" name="state" className="form-control entity-form" />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label">Zip Code</label>
                    <input value={form.zipCode} onChange={onChangeField} required type="text" name="zipCode" className="form-control entity-form" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <input type="checkbox" checked={form.isDefault == 1} onChange={(e) => setForm({ ...form, isDefault: form.isDefault === 1 ? 0 : 1 })} className="form-check-input mr-3" id="checkbox" />
                      <label for="checkbox" className="checbox_text_">Is Default?</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!isUpdatingList && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inactives":
      filteredBy = "In-Actives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Information of {name} ({filteredBy})</h2>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getUserInformation('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getUserInformation('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getUserInformation('inactives') }} className={flag === 'inactives' ? "active" : ""}>
                <a className="dropdown-item">In-Actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Verify</th>
                <th scope="col">Validate</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {userInformation && userInformation.length > 0 ?
                    <>
                      {userInformation.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.firstName ? `${item.firstName} ${item.lastName}` : "N/A"}</td>
                            <td>
                              {switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.active === 1 ? deactivateUserInformation({ userInformationId: item.id, userIdHash }) : activateUserInformation({ userInformationId: item.id, userIdHash })}
                                  checked={item.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>

                            <td>
                              {switch2Loading.status && item.id === switch2Loading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.isVerified === 1 ? verifyUserInformation({ userInformationId: item.id, value: item.isVerified === 1 ? 0 : 1 }) : verifyUserInformation({ userInformationId: item.id, value: item.isVerified === 1 ? 0 : 1 })}
                                  checked={item.isVerified === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>

                            <td>
                              {switch3Loading.status && item.id === switch3Loading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.isValidated === 1 ? validateUserInformation({ userInformationId: item.id, value: item.isValidated === 1 ? 0 : 1 }) : validateUserInformation({ userInformationId: item.id, value: item.isValidated === 1 ? 0 : 1 })}
                                  checked={item.isValidated === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>

                            <td>
                              {item.createdDate ?
                                <>
                                  {getFormatedDate(item.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(item.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>

                            <td className="table-action">
                              <a onClick={() => { getOneUserInformation(item.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <CustomModal
        modalIsOpen={modal.status}
        child={modalContent()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getUserInformation: bindActionCreators(getUserInformation, dispatch),
  getOneUserInformation: bindActionCreators(getOneUserInformation, dispatch),
  activateUserInformation: bindActionCreators(activateUserInformation, dispatch),
  deactivateUserInformation: bindActionCreators(deactivateUserInformation, dispatch),
  verifyUserInformation: bindActionCreators(verifyUserInformation, dispatch),
  validateUserInformation: bindActionCreators(validateUserInformation, dispatch),
  addUserInformation: bindActionCreators(addUserInformation, dispatch),
  editUserInformation: bindActionCreators(editUserInformation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersInformations);
