import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity attributes ********/
export const getEntityAttributes = (params, cb) => {
  let flag = "actives";
  if(params.flag){
    flag = params.flag;
  }

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute/${flag}`)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get entity attributes",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getOneEntityAttribute = (id, cb) => {

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute/${id}/entityAttribute`)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get entity type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const editEntityAttributes = (params, cb) => {

  return (dispatch) => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute/${params.id}`,params, true)
      .then((result) => {
        // if ((result.status >= 200 && result.status <= 202) && result.data) {
        if (!result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to edit entity attributes",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addEntityAttributes = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute`,params, true)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          // result = result.data;
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to add entity attributes",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deactivateEntityAttributes = (params, cb) => {

  return (dispatch) => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute/${params.id}`,true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to deactivate entity attributes",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const activateEntityAttributes = (params, cb) => {

  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/entityAttribute/${params.id}`,{},true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to activate entity attributes",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSearchedEntityAttribute = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/admin/searchEntityAttribute?search=${params}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get entityAttribute list",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};