import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin, userLogOut } from "../../actions/user";
import { loginSuccess } from '../../../redux/actions/auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Login = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "userName") {
            setEmail(value.trim());
        }
        else if (fieldName === "password") {
            setPassword(value);
        }
    };

    const handleLogin = (e) => {
        e.preventDefault();
        if (!email) {
            toast.error("Please enter user name or email address");
            return;
        }
        else if (!password) {
            toast.error("Please enter the password");
            return;
        }

        let params = {
            userNameOrEmailAddress: email,
            password: password,
            userTypeId: 3,
        };
        setLoading(true);
        props.userLogin(params, res => {
            setLoading(false);
            if (res.status && res.data.userType.userType === 'Admin') {
                let userData = {
                    userProfile: { ...res.data.userInfo, userType: res.data.userType, userName: res.data.userInformationInfo },
                    token: res.data.userInfo.userIdHash
                }
                cookies.set(
                    res.data.userType.sessionIdName,
                    res.data.userType.sessionId
                );
                props.onLoginSuccess(userData);
                history.push('/typeManagement');
            }
            else {
                let message = "You don't have access to admin."
                // if(res.message){
                //     message = res.message;
                // }
                toast.error(message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            }
        )
    };


    useEffect(() => {
        // props.logout();
    }, []);

    return (
        <>
            <div className="auth_content_area_">
                <h2 className="auth_heading_text_">Login to your account</h2>
                <form className="auth_form_" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Email Address</label>
                        <input type="text" className="form-control" placeholder="User Name or Email Address" autoComplete="off" value={email} onChange={(e) => { onChangeInput(e, 'userName') }} />
                    </div>
                    <div className="form-group">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="" className="form-label">Password
                            </label>
                        </div>
                        <input type="password" value={password} placeholder="Password" className="form-control" autoComplete="current-password" onChange={(e) => { onChangeInput(e, 'password') }} />
                        {/* <span className="toggle_password_">
                            <span className="show-icon" id="togglePassword"></span>
                        </span> */}
                    </div>
                    <div className="form-group">
                        <input type="checkbox" className="form-check-input" id="checkbox" name="remember_me" />
                        <label htmlFor="checkbox" className="checbox_text_">&nbsp;Remember Me</label>
                        {/* <span className="forgot_password_"><a href="#">Forgot Password?</a></span> */}
                    </div>
                    <button type="submit" className="primary_btn_">
                        {loading ?
                            <span className="spinner-border spinner-border-sm"></span>
                            :
                            <span>Sign In</span>
                        }

                    </button>
                    {/* <!-- <div className="social_details text-center">
                    <p><span className="left1"></span><span className="miffle">Or continue with</span><span className="right1"></span></p>
                    <ul className="social_icons">
                        <li className="social_item">
                            <a href="javscript:void(0);" className="social_link facebook">
                                <img src="img/facebook.png" alt="facebook">
                            </a>
                        </li>
                        <li className="social_item">
                            <a href="javscript:void(0);" className="social_link google">
                                <img src="img/google.png" alt="google">
                            </a>
                        </li>
                        <li className="social_item">
                            <a href="javscript:void(0);" className="social_link apple">
                                <img src="img/apple.png" alt="google">
                            </a>
                        </li>
                    </div> --> */}
                    {/* <!-- <div className="footer_form_content_">
                    <h4 className="footer_form_headtext_">Don’t have an account? <a href="#" className="create_account_">Create New</a></h4>
                    </div> --> */}
                </form>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    // userLogin: () => dispatch(userLogin())
    userLogin: bindActionCreators(userLogin, dispatch),
    onLoginSuccess: payload => dispatch(loginSuccess(payload)),
    logout: bindActionCreators(userLogOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);