import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import { debounce, getFormatedDate } from "../../../utilities/helpers";
import { activateUser, addUserData, deactivateUser, editUserData, getSearchedUsers, getUsersList, unverifyUser, updatePassword, verifyUser } from "../../actions/user/userList";

const UsersList = (props) => {
  let { subMenuId } = useParams();
  const selectedUserTypeId = subMenuId;
  const location = useLocation();
  const history = useHistory();

  const initField = {
    "password": "",
    "userEmailAddress": "",
  }

  const [loading, setLoading] = useState(false);
  const [gettingFields, setGettingFields] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [switch2Loading, setSwitch2Loading] = useState({ status: false, id: null });

  const [flag, setFlag] = useState("actives");
  const [usersList, setUsersList] = useState([]);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getUsersList = (flag, noLoad) => {
    setFlag(flag);
    if (!noLoad) {
      setLoading(true);
    }
    props.getUsersList({ flag, selectedUserTypeId }, res => {
      setSwitchLoading({ status: false, id: null });
      setSwitch2Loading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let users = res.data;
        let sortedUserList = users && users.length > 0 ? users.sort((a, b) => a.userEmailAddress.toLowerCase() > b.userEmailAddress.toLowerCase() ? 1 : -1) : [];
        setUsersList(sortedUserList);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        //   toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const getSearchedUsers = (value) => {
    setLoading(true);
    props.getSearchedUsers(value, res => {
      setLoading(false);
      if (res.status) {
        let users = res.data;
        let sortedUserList = users && users.length > 0 ? users.sort((a, b) => a.userEmailAddress.toLowerCase() > b.userEmailAddress.toLowerCase() ? 1 : -1) : [];
        setUsersList(sortedUserList);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        //   toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const addUserData = (data) => {
    setUpdatingList(true);
    props.addUserData(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getUsersList(flag);
        setForm(initField);
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("User created successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const updatePassword = (data) => {
    setUpdatingList(true);
    props.updatePassword(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getUsersList(flag);
        setForm(initField);
        setModal({ status: !modal.status, type: 'Update Password' });
        toast.success("User password updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const activateUser = (data) => {
    setSwitchLoading({ status: true, id: data.userIdHash });
    props.activateUser(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getUsersList(flag, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateUser = (data) => {
    setSwitchLoading({ status: true, id: data.userIdHash });
    props.deactivateUser(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getUsersList(flag, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const verifyUser = (data) => {
    setSwitch2Loading({ status: true, id: data.userIdHash });
    props.verifyUser(data, res => {
      setSwitch2Loading({ status: false, id: null });
      if (res.status) {
        getUsersList(flag, true);
        toast.success("Verified successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitch2Loading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitch2Loading({ status: false, id: null });
      })
  }

  const unverifyUser = (data) => {
    setSwitch2Loading({ status: true, id: data.userIdHash });
    props.unverifyUser(data, res => {
      setSwitch2Loading({ status: false, id: null });
      if (res.status) {
        getUsersList(flag, true);
        toast.success("Unverified successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitch2Loading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitch2Loading({ status: false, id: null });
      })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    getUsersList(flag);
  }, [history.location.pathname]);

  const onSubmitForm = () => {
    if (!form.password) {
      toast.error('Please enter user password');
      return;
    } else if (!form.userEmailAddress) {
      toast.error('Please enter user email');
      return;
    }
    if (modal.type === 'Add') {
      addUserData({ id: selectedUserTypeId, data: form });
    } else if (modal.type === 'Update Password') {
      updatePassword({ userIdHash: form.userIdHash, password: form.password });
    }
  }

  const onClickCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
  }

  const onClickEdit = (item) => {
    setModal({ status: !modal.status, type: 'Update Password' });
    setForm(item);
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getSearchedUsers(value);
    } else {
      getUsersList(flag);
    }
  }

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  const userType = location.state;

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} {modal.type === "Add" && userType?.name}</h5>
          </div>
          {gettingFields ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  {modal.type === "Add" && <div className="col-md-12 mb-3">
                    <input value={form.userEmailAddress} onChange={onChangeField} type="text" name="userEmailAddress" placeholder="User e-mail" className="form-control entity-form" id="entityname" />
                  </div>}
                  <div className="col-md-12 mb-3">
                    <input value={form.password} onChange={onChangeField} type="text" name="password" placeholder="Password" className="form-control entity-form" id="entityname" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {!updatingList && modal.status ? <>
                  <button onClick={onClickCancel} type="button" className="cancle_btn_">Cancel</button>
                  <button onClick={onSubmitForm} type="submit" name="submit" className="info_btn_">Submit</button></>
                  : <span className="spinner-border spinner-border-sm"></span>}
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inActives":
      filteredBy = "In-Actives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">{userType?.name} ({filteredBy})</h2>
          <div className="col-md-6">
            <input onChange={optimizedSearch} type="text" placeholder="Search" className="form-control entity-form" />
          </div>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              {/* <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li> */}
              <li onClick={() => { getUsersList('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getUsersList('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getUsersList('inActives') }} className={flag === 'inActives' ? "active" : ""}>
                <a className="dropdown-item">In-Actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">email</th>
                <th scope="col">Status</th>
                <th scope="col">Verify</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {usersList && usersList.length > 0 ?
                    <>
                      {usersList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td title={item.userEmailAddress}>{item.userEmailAddress ?
                              item.userEmailAddress.length > 20 ? item.userEmailAddress.substring(0, 20) + ' . . .' : item.userEmailAddress
                              :
                              "N/A"
                            }</td>
                            <td>
                              {switchLoading.status && item.userIdHash === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.active === 1 ? deactivateUser({ userIdHash: item.userIdHash }) : activateUser({ userIdHash: item.userIdHash })}
                                  checked={item.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}

                            </td>
                            <td>
                              {switch2Loading.status && item.userIdHash === switch2Loading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.isVerified === 1 ? unverifyUser({ userIdHash: item.userIdHash }) : verifyUser({ userIdHash: item.userIdHash })}
                                  checked={item.isVerified === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}

                            </td>
                            <td>
                              {item.createdDate ?
                                <>
                                  {getFormatedDate(item.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(item.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                              <a onClick={() => { onClickEdit(item) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                              <div class="btns_ m-2">
                                <button class="update_" onClick={() => {
                                  history.push({
                                    pathname: `/userDetails/${item.userIdHash}`,
                                    state: { name: item.userEmailAddress, userTypeId: selectedUserTypeId }
                                  })
                                }}>Profiles</button>
                              </div>
                              <div class="btns_ ml-2">
                                <button class="update_" onClick={() => {
                                  history.push({
                                    pathname: `/userInformations`,
                                    state: { name: item.userEmailAddress, userIdHash: item.userIdHash }
                                  })
                                }}>Informations</button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getUsersList: bindActionCreators(getUsersList, dispatch),
  addUserData: bindActionCreators(addUserData, dispatch),
  activateUser: bindActionCreators(activateUser, dispatch),
  deactivateUser: bindActionCreators(deactivateUser, dispatch),
  verifyUser: bindActionCreators(verifyUser, dispatch),
  unverifyUser: bindActionCreators(unverifyUser, dispatch),
  updatePassword: bindActionCreators(updatePassword, dispatch),
  getSearchedUsers: bindActionCreators(getSearchedUsers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
