import React from "react";
import { connect } from "react-redux";
// import profileImage from "../../public/images/user-profile.png";

const Header = (props) => {
  let userName = "User Name";
  if (props.auth.isLoggedIn && props.auth.userProfile) {
    if (props.auth.userProfile.userName) {
      userName = props.auth.userProfile.userName.firstName + " " + props.auth.userProfile.userName.lastName;
    }
    else if (props.auth.userProfile.userType && props.auth.userProfile.userType.userType == "Admin") {
      userName = "Admin";
    }
  }
  let nameFirstLetter = userName.charAt(0);

  return (
    <>
      <header className="topbar" style={{ position: "fixed" }}>
        <div className="topbar-items">
          <a>
            <div className="media user-profile d-flex align-items-center me-5">
              {/* <div className="media-aside">
                  <span className="user-img">
                  <img src={profileImage} alt=""/>
                  </span>
                </div> */}
              <div className="user-content">

                <h5 className="user-heading mt-2">
                  <span className="header-profile-name">{nameFirstLetter}</span>
                  {userName}
                </h5>
              </div>
            </div>
          </a>
          <div className="bars" onClick={window['openNav']}>
            {/* <div className="bars" onClick={() => {alert('Test')}}> */}
            <span className="hamburger openbtn">
              <span className="hamburger-line-1 ham_com"></span>
              <span className="hamburger-line-2 ham_com"></span>
            </span>
            {/* <!-- <span style="font-size:30px;cursor:pointer" onclick="openNav()"><i className="fal fa-bars"></i></span> --> */}
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
