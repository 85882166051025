import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addUserProfile,
  editUserProfile,
  getOneUserProfile,
} from "../../actions/user/userDetails";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getEntityTypes } from "../../actions/entityType";
import { getEmploymentType } from "../../actions/typeManagement/employmentType";
import { getFacilityType } from "../../actions/typeManagement/facilityType";
import { getPayrollType } from "../../actions/typeManagement/payrollType";
import { getScheduleType } from "../../actions/typeManagement/scheduleType";
import { getShiftType } from "../../actions/typeManagement/shiftType";
import { getPaymentType } from "../../actions/typeManagement/paymentType";
import { getEntityTypesChildren } from "../../actions/entityType/sideBarOption";

const UserProfile = (props) => {
  const location = useLocation();
  const state = location.state;
  const actionType = state.userProfileId ? "Edit" : "Add";

  const initField = {
    about: "",
    address: "",
    address1: "",
    city: "",
    country: "",
    countryAbbr: "",
    employmentTypeId: 0,
    entityId: 0,
    entityName: "",
    entityTypeId: 0,
    facilityTypeId: 0,
    firstName: "",
    lastName: "",
    latitude: 0,
    locationId: 0,
    longitude: 0,
    middleName: "",
    minExperience: 0,
    minHourlyPayment: 0,
    minSalaryPayment: 0,
    paymentTypeId: 0,
    payrollTypeId: 0,
    perspectiveId: 0,
    phoneNumber: "",
    scheduleTypeId: 0,
    selectedEntityTypeIds: "",
    shiftTypeId: 0,
    state: "",
    stateAbbr: "",
    unitId: "",
    uploadDirectory: "",
    userDepartmentId: 0,
    userEmailAddress: "",
    userId: 0,
    userName: "",
    userProfileId: 0,
    withinMile: 0,
    zipCode: "",
  };

  const [form, setForm] = useState(initField);
  const [gettingFields, setGettingFields] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [facilityTypes, setFacilityTypes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [payrollTypes, setPayrollTypes] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [activeEntityTypes, setActiveEntityTypes] = useState([]);

  const [skillFlag1, setSkillFlag1] = useState(false);
  const [skillFlag2, setSkillFlag2] = useState(false);
  const [skillFlag3, setSkillFlag3] = useState(false);
  const [skillFlag4, setSkillFlag4] = useState(false);
  const [skillFlag5, setSkillFlag5] = useState(false);

  const [skillData1, setSkillData1] = useState([]);
  const [skillData2, setSkillData2] = useState([]);
  const [skillData3, setSkillData3] = useState([]);
  const [skillData4, setSkillData4] = useState([]);
  const [skillData5, setSkillData5] = useState([]);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() });
  };

  const getOneUserProfile = () => {
    setGettingFields(true);
    props.getOneUserProfile(
      state.userProfileId,
      (res) => {
        setGettingFields(false);
        if (res.status) {
          setForm(res.data);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
        setGettingFields(false);
      }
    );
  };

  const addUserProfile = (data) => {
    setUpdatingList(true);
    props.addUserProfile(
      {
        userTypeId: state.userTypeId,
        userIdHash: state.userIdHash,
        data: data,
      },
      (res) => {
        setUpdatingList(false);
        if (res.status) {
          toast.success("Userprofile created successfully");
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      }
    );
  };

  const editUserProfile = (data) => {
    setUpdatingList(true);
    props.editUserProfile(
      data,
      (res) => {
        setUpdatingList(false);
        if (res.status) {
          setForm(initField);
          getOneUserProfile();
          toast.success("Userprofile updated successfully");
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      }
    );
  };

  const getEntityTypes = () => {
    props.getEntityTypes(
      { flag: "tops" },
      (res) => {
        if (res.status) {
          let entityTypes = res.data;
          let sortedEntityTypes =
            entityTypes && entityTypes.length > 0
              ? entityTypes.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setActiveEntityTypes(sortedEntityTypes);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getEntityTypesChildren = (id) => {
    props.getEntityTypesChildren(id, (res) => {
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes =
          entityTypes && entityTypes.length > 0
            ? entityTypes.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            : [];
        if (!skillFlag1) {
          setSkillFlag1(true);
          setSkillData1(sortedEntityTypes);
        }
        if (skillFlag1 && !skillFlag2) {
          setSkillFlag2(true);
          setSkillData2(sortedEntityTypes);
        }
        if (skillFlag2 && !skillFlag3) {
          setSkillFlag3(true);
          setSkillData3(sortedEntityTypes);
        }
        if (skillFlag3 && !skillFlag4) {
          setSkillFlag4(true);
          setSkillData4(sortedEntityTypes);
        }
        if (skillFlag4 && !skillFlag5) {
          setSkillFlag5(true);
          setSkillData5(sortedEntityTypes);
        }
      }
    });
  };

  const getEmploymentType = () => {
    props.getEmploymentType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setEmploymentTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getFacilityType = () => {
    props.getFacilityType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setFacilityTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getPaymentType = () => {
    props.getPaymentType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setPaymentTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getPayrollType = () => {
    props.getPayrollType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setPayrollTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getScheduleType = () => {
    props.getScheduleType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setScheduleTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const getShiftType = () => {
    props.getShiftType(
      { flag: "actives" },
      (res) => {
        if (res.status) {
          let data = res.data;
          let sortedData =
            data && data.length > 0
              ? data.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              : [];
          setShiftTypes(sortedData);
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const onSubmitForm = () => {
    if (!form.firstName) {
      toast.error("Please enter first name");
      return;
    } else if (!form.lastName) {
      toast.error("Please enter last name");
      return;
    }
    if (actionType === "Add") {
      addUserProfile({ ...form });
    } else if (actionType === "Edit") {
      editUserProfile({
        userProfileId: state.userProfileId,
        firstName: form.firstName,
        lastName: form.lastName,
        entityName: form.entityName,
        about: form.about,
        phoneNumber: form.phoneNumber,
        address: form.address,
        address1: form.address1,
        city: form.city,
        state: form.state,
        zipCode: form.zipCode,
        country: form.country,
        selectedEntityTypeIds: form.selectedEntityTypeIds.toString(),
      });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (actionType === "Edit") {
      getOneUserProfile();
    }
    getEntityTypes();
    getEmploymentType();
    getFacilityType();
    getPaymentType();
    getShiftType();
    getScheduleType();
    getPayrollType();
  }, []);

  const removeAllSkillField = () => {
    setSkillFlag1(false);
    setSkillFlag2(false);
    setSkillFlag3(false);
    setSkillFlag4(false);
    setSkillFlag5(false);
  };

  // console.log(form)

  return (
    <div className="content_area me-0 me-xxl-5">
      <div className="header-title">
        <h2 className="heading-text">User Account</h2>
      </div>
      <div class="tabs_">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li
            onClick={() => setSelectedTab(1)}
            class="nav-item"
            role="presentation"
          >
            <a
              class={selectedTab === 1 ? "nav-link active" : "nav-link"}
              id="personalInfo-tab"
              data-bs-toggle="pill"
              role="tab"
              aria-controls="personalInfo"
              aria-selected="true"
            >
              Basic Info
            </a>
          </li>
          <li
            onClick={() => setSelectedTab(2)}
            class="nav-item"
            role="presentation"
          >
            <a
              class={selectedTab === 2 ? "nav-link active" : "nav-link"}
              id="profession-tab"
              data-bs-toggle="pill"
              role="tab"
              aria-controls="profession"
              aria-selected="true"
            >
              Profession
            </a>
          </li>
          <li
            onClick={() => setSelectedTab(3)}
            class="nav-item"
            role="presentation"
          >
            <a
              class={selectedTab === 3 ? "nav-link active" : "nav-link"}
              id="location-tab"
              data-bs-toggle="pill"
              role="tab"
              aria-controls="location"
              aria-selected="true"
            >
              Location
            </a>
          </li>
          <li
            onClick={() => setSelectedTab(4)}
            class="nav-item"
            role="presentation"
          >
            <a
              class={selectedTab === 4 ? "nav-link active" : "nav-link"}
              id="demo-tab"
              data-bs-toggle="pill"
              role="tab"
              aria-controls="demo"
              aria-selected="true"
            >
              Work Details
            </a>
          </li>
          <li
            onClick={() => setSelectedTab(5)}
            class="nav-item"
            role="presentation"
          >
            <a
              class={selectedTab === 5 ? "nav-link active" : "nav-link"}
              id="demo-tab"
              data-bs-toggle="pill"
              role="tab"
              aria-controls="demo"
              aria-selected="true"
            >
              About
            </a>
          </li>
        </ul>
        <div class="tab-content mt-5">
          {selectedTab === 1 ? (
            <form class="profile-form mt-4">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="label">First Name</label>
                  <input
                    value={form.firstName}
                    onChange={onChangeField}
                    name="firstName"
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Last Name</label>
                  <input
                    value={form.lastName}
                    onChange={onChangeField}
                    name="lastName"
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Business Name</label>
                  <input
                    value={form.entityName}
                    onChange={onChangeField}
                    name="entityName"
                    type="text"
                    class="form-control"
                    placeholder="Business Name"
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Phone Number</label>
                  <PhoneInput
                    value={form.phoneNumber}
                    onChange={(e, c) => {
                      setForm({ ...form, phoneNumber: e });
                    }}
                    placeholder="Phone Number"
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "50px",
                      borderColor: "#ced4da",
                    }}
                    country={"us"}
                  />
                </div>
              </div>
            </form>
          ) : selectedTab === 2 ? (
            <form class="profession">
              <select
                onChange={(e) => {
                  setForm({ ...form, selectedEntityTypeIds: [e.target.value] });
                  removeAllSkillField();
                  getEntityTypesChildren(e.target.value);
                }}
                class="form-select mb-3"
                aria-label="Default select example"
              >
                {activeEntityTypes.length > 0 &&
                  activeEntityTypes.map((entityType) => (
                    <option value={entityType.id}>{entityType.name}</option>
                  ))}
              </select>
              {skillFlag1 && (
                <select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      selectedEntityTypeIds: [
                        ...form.selectedEntityTypeIds,
                        e.target.value,
                      ],
                    });
                    getEntityTypesChildren(e.target.value);
                  }}
                  class="form-select mb-3"
                  aria-label="Default select example"
                >
                  {skillData1.length > 0 &&
                    skillData1.map((entityType) => (
                      <option value={entityType.id}>{entityType.name}</option>
                    ))}
                </select>
              )}
              {skillFlag2 && (
                <select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      selectedEntityTypeIds: [
                        ...form.selectedEntityTypeIds,
                        e.target.value,
                      ],
                    });
                    getEntityTypesChildren(e.target.value);
                  }}
                  class="form-select mb-3"
                  aria-label="Default select example"
                >
                  {skillData2.length > 0 &&
                    skillData2.map((entityType) => (
                      <option value={entityType.id}>{entityType.name}</option>
                    ))}
                </select>
              )}
              {skillFlag3 && (
                <select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      selectedEntityTypeIds: [
                        ...form.selectedEntityTypeIds,
                        e.target.value,
                      ],
                    });
                    getEntityTypesChildren(e.target.value);
                  }}
                  class="form-select mb-3"
                  aria-label="Default select example"
                >
                  {skillData3.length > 0 &&
                    skillData3.map((entityType) => (
                      <option value={entityType.id}>{entityType.name}</option>
                    ))}
                </select>
              )}
              {skillFlag4 && (
                <select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      selectedEntityTypeIds: [
                        ...form.selectedEntityTypeIds,
                        e.target.value,
                      ],
                    });
                    getEntityTypesChildren(e.target.value);
                  }}
                  class="form-select mb-3"
                  aria-label="Default select example"
                >
                  {skillData4.length > 0 &&
                    skillData4.map((entityType) => (
                      <option value={entityType.id}>{entityType.name}</option>
                    ))}
                </select>
              )}
              {skillFlag5 && (
                <select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      selectedEntityTypeIds: [
                        ...form.selectedEntityTypeIds,
                        e.target.value,
                      ],
                    });
                    getEntityTypesChildren(e.target.value);
                  }}
                  class="form-select mb-3"
                  aria-label="Default select example"
                >
                  {skillData5.length > 0 &&
                    skillData5.map((entityType) => (
                      <option value={entityType.id}>{entityType.name}</option>
                    ))}
                </select>
              )}
            </form>
          ) : selectedTab === 3 ? (
            <form class="profile-form mt-4">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="label">Address Line 1</label>
                  <input
                    value={form.address}
                    onChange={onChangeField}
                    name="address"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Address Line 2</label>
                  <input
                    value={form.address1}
                    onChange={onChangeField}
                    name="address1"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">City</label>
                  <input
                    value={form.city}
                    onChange={onChangeField}
                    name="city"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">State</label>
                  <input
                    value={form.state}
                    onChange={onChangeField}
                    name="state"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Zip Code</label>
                  <input
                    value={form.zipCode}
                    onChange={onChangeField}
                    name="zipCode"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Country</label>
                  <input
                    value={form.country}
                    onChange={onChangeField}
                    name="country"
                    type="text"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>
              </div>
            </form>
          ) : selectedTab === 4 ? (
            <form class="profession">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="label">Employment Type</label>
                  <select
                    value={form.employmentTypeId}
                    onChange={onChangeField}
                    name="employmentTypeId"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {employmentTypes.length > 0 &&
                      employmentTypes.map((employmentType) => (
                        <option value={employmentType.id}>
                          {employmentType.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Schedule Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {scheduleTypes.length > 0 &&
                      scheduleTypes.map((scheduleType) => (
                        <option value={scheduleType.id}>
                          {scheduleType.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Facility Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {facilityTypes.length > 0 &&
                      facilityTypes.map((facilityType) => (
                        <option value={facilityType.id}>
                          {facilityType.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Payroll Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {payrollTypes.length > 0 &&
                      payrollTypes.map((payrollType) => (
                        <option value={payrollType.id}>
                          {payrollType.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Shift Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {shiftTypes.length > 0 &&
                      shiftTypes.map((shiftType) => (
                        <option value={shiftType.id}>{shiftType.name}</option>
                      ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label class="label">Payment Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    {paymentTypes.length > 0 &&
                      paymentTypes.map((paymentType) => (
                        <option value={paymentType.id}>
                          {paymentType.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </form>
          ) : selectedTab === 5 ? (
            <div class="form-group col-md-12">
              <label class="label">About</label>
              <textarea
                value={form.about}
                onChange={onChangeField}
                name="about"
                type="text"
                class="form-control"
                placeholder="About"
                required
              />
            </div>
          ) : null}
          <div class="col-md-12 text-center">
            <div class="btns_ mt-3">
              <a class="update_" onClick={onSubmitForm}>
                {actionType === "Add" ? "Save" : "Update"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  addUserProfile: bindActionCreators(addUserProfile, dispatch),
  editUserProfile: bindActionCreators(editUserProfile, dispatch),
  getOneUserProfile: bindActionCreators(getOneUserProfile, dispatch),
  getEntityTypes: bindActionCreators(getEntityTypes, dispatch),
  getEmploymentType: bindActionCreators(getEmploymentType, dispatch),
  getFacilityType: bindActionCreators(getFacilityType, dispatch),
  getPayrollType: bindActionCreators(getPayrollType, dispatch),
  getScheduleType: bindActionCreators(getScheduleType, dispatch),
  getShiftType: bindActionCreators(getShiftType, dispatch),
  getPaymentType: bindActionCreators(getPaymentType, dispatch),
  getEntityTypesChildren: bindActionCreators(getEntityTypesChildren, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
