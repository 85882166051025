import { Switch, Route } from 'react-router-dom';

import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Login} />

      <Layouts.PrivateRoute exact path="/home" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/typeManagement" component={Containers.TypeManagement} />
      <Layouts.PrivateRoute exact path="/typeManagement/AvailabilityStatus" component={Containers.AvailabilityStatus} />
      <Layouts.PrivateRoute exact path="/typeManagement/BookingStatus" component={Containers.BookingStatus} />
      <Layouts.PrivateRoute exact path="/typeManagement/ScheduleType" component={Containers.ScheduleType} />
      <Layouts.PrivateRoute exact path="/typeManagement/ShiftType" component={Containers.ShiftType} />
      <Layouts.PrivateRoute exact path="/typeManagement/EcommServiceKey" component={Containers.EcommServiceKey} />
      <Layouts.PrivateRoute exact path="/typeManagement/UserDeviceType" component={Containers.UserDeviceType} />
      <Layouts.PrivateRoute exact path="/typeManagement/ContactUsType" component={Containers.ContactUsType} />
      <Layouts.PrivateRoute exact path="/typeManagement/ContactUsPriorityType" component={Containers.ContactUsPriorityType} />
      <Layouts.PrivateRoute exact path="/typeManagement/NotificationType" component={Containers.NotificationType} />
      <Layouts.PrivateRoute exact path="/typeManagement/NotificationPriorityType" component={Containers.NotificationPriorityType} />
      <Layouts.PrivateRoute exact path="/typeManagement/ServiceTermType" component={Containers.ServiceTermType} />
      <Layouts.PrivateRoute exact path="/typeManagement/EmailAddressType" component={Containers.EmailAddressType} />
      <Layouts.PrivateRoute exact path="/typeManagement/ServiceTerm" component={Containers.ServiceTerm} />
      <Layouts.PrivateRoute exact path="/typeManagement/SpaceType" component={Containers.SpaceType} />
      <Layouts.PrivateRoute exact path="/typeManagement/OpenApplyQuestion" component={Containers.OpenApplyQuestion} />
      <Layouts.PrivateRoute exact path="/typeManagement/OpenApplyOption" component={Containers.OpenApplyOption} />
      <Layouts.PrivateRoute exact path="/typeManagement/EmploymentType" component={Containers.EmploymentType} />


      <Layouts.PrivateRoute exact path="/typeManagement/PayCurrencyType" component={Containers.PayCurrencyType} />
      <Layouts.PrivateRoute exact path="/typeManagement/PayScaleType" component={Containers.PayScaleType} />


      <Layouts.PrivateRoute exact path="/typeManagement/PayrollType" component={Containers.PayrollType} />

      <Layouts.PrivateRoute exact path="/typeManagement/PayrollType" component={Containers.PayrollType} />



      <Layouts.PrivateRoute exact path="/typeManagement/PayrollType" component={Containers.PayrollType} />      
      <Layouts.PrivateRoute exact path="/typeManagement/FacilityType" component={Containers.FacilityType} />
      <Layouts.PrivateRoute exact path="/typeManagement/FileType" component={Containers.FileType} />
      <Layouts.PrivateRoute exact path="/typeManagement/File" component={Containers.File} />
      <Layouts.PrivateRoute exact path="/typeManagement/FileTypeMime" component={Containers.FileTypeMime} />
      <Layouts.PrivateRoute exact path="/typeManagement/PageContentType" component={Containers.PageContentType} />
      <Layouts.PrivateRoute exact path="/typeManagement/PageContent" component={Containers.PageContent} />
      <Layouts.PrivateRoute exact path="/typeManagement/ScheduleStatus" component={Containers.ScheduleStatus} />
      <Layouts.PrivateRoute exact path="/typeManagement/UserStatus" component={Containers.UserStatus} />
      <Layouts.PrivateRoute exact path="/typeManagement/UserType" component={Containers.UserType} />
      <Layouts.PrivateRoute exact path="/typeManagement/UserTypeCategory/:id" component={Containers.UserTypeCategory} />

      <Layouts.PrivateRoute exact path="/entityTypes" component={Containers.EntityTypes} />
      <Layouts.PrivateRoute exact path="/entityTypes/:subMenuId" component={Containers.ChildrenEntityTypes} />
      <Layouts.PrivateRoute exact path="/perspective" component={Containers.Perspective} />
      <Layouts.PrivateRoute exact path="/entityAttributes" component={Containers.EntityAttributes} />
      <Layouts.PrivateRoute exact path="/users" component={Containers.Users} />
      <Layouts.PrivateRoute exact path="/users/:subMenuId" component={Containers.UsersList} />
      <Layouts.PrivateRoute exact path="/entityAttribueFields/:subMenuId" component={Containers.EntityAttribueFields} />
      <Layouts.PrivateRoute exact path="/userDetails/:subMenuId" component={Containers.UsersDetails} />
      <Layouts.PrivateRoute exact path="/userProfile" component={Containers.UsersProfile} />
      <Layouts.PrivateRoute exact path="/userInformations" component={Containers.UserInformations} />
      <Route component={Containers.NotFound} />
    </Switch>

  );
}

export default Router;