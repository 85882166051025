import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity Types ********/
export const getUsersList = (params, cb) => {
  let flag = "actives";
  if(params.flag){
    flag = params.flag;
  }

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/user/${flag}/${params.selectedUserTypeId}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get users list",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addUserData = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/user/${params.id}`, params.data, true)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          // result = result.data;
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to add user",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deactivateUser = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/user/${params.userIdHash}`, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to deactivate user",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const activateUser = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/user/${params.userIdHash}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to activate user",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const unverifyUser = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/user/unverify?userIdHash=${params.userIdHash}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to unverify user",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const verifyUser = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/user/verify?userIdHash=${params.userIdHash}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to verify user",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const updatePassword = (params, cb) => {
  return (dispatch) => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/user/updatepassword?newPassword=${params.password}&userIdHash=${params.userIdHash}`, {}, true)
      .then((result) => {
        // if ((result.status >= 200 && result.status <= 202) && result.data) {
        if (!result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to update password",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSearchedUsers = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/admin/searchUser?search=${params}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get users list",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};