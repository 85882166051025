import { MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const processGenesisData = (entities) => {
  let entityTypes = Object.values(entities);
  let formatedEntities = entityTypes.map((item, index) => {
    let entityTypeObj = { value: Object.keys(entities)[index] };
    let title = "";
    Object.values(item).map((item2) => {
      if (item2.title) {
        title = item2.title;
      }
      entityTypeObj = { ...entityTypeObj, ...item2 };
    });

    if (!entityTypeObj.name) {
      entityTypeObj = { ...entityTypeObj, name: title };
    }
    return entityTypeObj;
  });
  return formatedEntities;
};

const GenisisSelect = ({
  fieldMetaData,
  onChange,
  value,
  props,
  parentFieldValue
}) => {
  const [options, setOptions] = useState([]);

  const getGenesis = (id) => {
    props.getGenesis(id, res => {
      if (res.status) {
        setOptions(processGenesisData(res.data));
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
      })
  }

  const getChildrenData = (params) => {
    props.getChildrenData(params, res => {
      if (res.status) {
        setOptions(res.data[0].children && processGenesisData(res.data[0]?.children));
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
      })
  }

  useEffect(() => {
    if (parentFieldValue !== undefined) {
      onChange(fieldMetaData.entityAttributeFieldKey, null, null);
      if (!parentFieldValue) return;
      const payload = {
        entityId: parentFieldValue,
      };
      if (fieldMetaData.perspectiveId) {
        payload.perspectiveId = fieldMetaData.perspectiveId;
      } else {
        payload.entityTypeId = fieldMetaData.entityTypeId;
      }
      getChildrenData(payload);

    } else if (fieldMetaData.genesisId) {
      getGenesis(fieldMetaData.genesisId);
    }
  }, [parentFieldValue]);

  // if (!options) return null;
  return (
    <>
      <label className="label">{fieldMetaData.fieldName}</label>
      <Select
        style={{ padding: 0 }}
        className="form-select entity-form"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          }
        }}
        onChange={(e) => {
          onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e);
        }}
        name={fieldMetaData.entityAttributeFieldKey}
        label={fieldMetaData.fieldName}
        defaultValue={fieldMetaData.fieldValue}
        value={value === undefined ? fieldMetaData.fieldValue : value || ""}
        required={fieldMetaData.isRequired === 1}
      >
        {!options?.length ? (
          <MenuItem value={null}>No Data </MenuItem>
        ) : (
          options.map((selectOption) => (
            <MenuItem value={selectOption.value} key={selectOption.value}>
              {selectOption.name}
            </MenuItem>
          ))
        )}
      </Select>
    </>
  );
};

export default function inputFieldGenerator(
  fieldMetaData,
  value,
  onChange,
  parentFieldValue,
  props
) {
  switch (fieldMetaData?.fieldDataType.trim()) {
    case "text":
      if (fieldMetaData.entityAttributeFieldKey == "description") {
        return (
          <textarea
            value={value}
            onChange={(e) => onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)}
            required={fieldMetaData.isRequired === 1}
            type="text"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            placeholder={fieldMetaData.fieldName}
            className="form-control entity-form"
            rows={5}
          />
        );
      } else {
        return (
          <input
            value={value}
            onChange={(e) => onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)}
            required={fieldMetaData.isRequired === 1}
            type="text"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            placeholder={fieldMetaData.fieldName}
            className="form-control entity-form"
          />
        );
      }

    case "textarea":
      return (
        <textarea
          value={value}
          onChange={(e) => onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)}
          required={fieldMetaData.isRequired === 1}
          type="text"
          name={fieldMetaData.entityAttributeFieldKey}
          defaultValue={fieldMetaData.fieldValue}
          placeholder={fieldMetaData.fieldName}
          className="form-control entity-form"
          rows={5}
        />
      );

    case "integer":
      return (
        <input
          type="number"
          name={fieldMetaData.entityAttributeFieldKey}
          placeholder={fieldMetaData.fieldName}
          defaultValue={fieldMetaData.fieldValue}
          value={value}
          required={fieldMetaData.isRequired === 1}
          onChange={(e) =>
            onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
          }
          className="form-control entity-form"
        />
      );

    case "decimal":
      return (
        <input
          type="number"
          name={fieldMetaData.entityAttributeFieldKey}
          placeholder={fieldMetaData.fieldName}
          defaultValue={fieldMetaData.fieldValue}
          value={value}
          required={fieldMetaData.isRequired === 1}
          onChange={(e) =>
            onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
          }
          className="form-control entity-form"
        />
      );

    case "boolean":
      return (
        <input
          type="text"
          name={fieldMetaData.entityAttributeFieldKey}
          placeholder={fieldMetaData.fieldName}
          defaultValue={fieldMetaData.fieldValue}
          value={value}
          required={fieldMetaData.isRequired === 1}
          onChange={(e) =>
            onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
          }
          className="form-control entity-form"
        />
      );

    case "datetime":
      return (
        <>
          <label className="label">{fieldMetaData.fieldName}</label>
          <input
            type="date"
            name={fieldMetaData.entityAttributeFieldKey}
            placeholder={fieldMetaData.fieldName}
            defaultValue={fieldMetaData.fieldValue}
            value={value}
            required={fieldMetaData.isRequired === 1}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            className="form-control entity-form"
          />
        </>
      );

    case "genesis":
      return (
        <GenisisSelect
          fieldMetaData={fieldMetaData}
          onChange={onChange}
          value={value}
          required={fieldMetaData.isRequired === 1}
          props={props}
          parentFieldValue={parentFieldValue}
        />
      );

    default:
      return null;

    // We have to add more type based on requirement
  }
}
