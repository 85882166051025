import React from 'react';
import { connect } from 'react-redux';
import AuthLogo from "../../public/images/auth-logo.png";

const AuthLayout = (props) => {

    return (
        <>
            {/* <section className="vh-100">
                <div className="container py-5 h-100">
                    <div className="row d-flex align-items-center justify-content-center h-100">
                        <div className="col-md-8 col-lg-7 col-xl-6">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="img-fluid" alt="logo"/>
                        </div>
                        {props.children}
                    </div>
                </div>
            </section> */}
            <section className="user_auth">
                <div className="row common_userauth_area_ align-items-center">
                    <div className="col-sm-6 col-md-6 bg_color_left">
                         <div className="auth_img_">
                            <a>
                                <img src={AuthLogo} className="w-100"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mx-auto">
                        {props.children}
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
 });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);