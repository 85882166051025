import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { addEntity, editEntity, getChildrenData, getChildrenEntityTypes, getEditFormFields, getFormFields, getGenesis } from "../../actions/entityType/childrenEntityTypes";
import CustomModal from "../../components/shared/CustomModal";
import { useParams, useHistory } from "react-router-dom";
import inputFieldGenerator from "./fieldGenerator";
import { useLocation } from "react-router-dom";

const ChildrenEntityTypes = (props) => {
  let { subMenuId } = useParams();
  const location = useLocation();

  
  const selectedEtityTypeId = subMenuId;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [gettingFields, setGettingFields] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [childrenEntityTypes, setChildrenEntityTypes] = useState([]);
  const [modal, setModal] = useState({status:false, type:'Add'});
  const [fieldValues, setFieldValues] = useState({});
  const [fieldMetaData, setFieldMetaData] = useState(null);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const payLoad = location.state.isPrespective!==undefined && location.state.isPrespective ? {perspectiveId : selectedEtityTypeId}:{entityTypeId : selectedEtityTypeId};

  const onInputChange = (fieldName, value, e) => {
    setFieldValues(() => ({
      ...fieldValues,
      [fieldName]: value,
    }));
  };
  
  const getChildrenEntityTypes = (selectedEtityType) => {

    setLoading(true);
    props.getChildrenEntityTypes(selectedEtityType, res => {
      setLoading(false);
      if(res.status){
        let entityTypes = Object.values(res.data);
        let formatedEntities = entityTypes.map((item, index) => {
          let entityTypeObj = {entityId: Object.keys(res.data)[index]};
          Object.values(item).map((item2) => {
            entityTypeObj = {...entityTypeObj, ...item2};
          });
          return entityTypeObj;
        });
        setChildrenEntityTypes(formatedEntities);
      }
      else {
        setChildrenEntityTypes([]);
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        // toast.error(message);
      }
    },
    err => {
      setChildrenEntityTypes([]);
      toast.error("Something went wrong!");
      setLoading(false);
    })
  }

  const addEntity = (data) => {
    setUpdatingList(true);
    props.addEntity(data, res => {
      setUpdatingList(false);
      if(res.status){
        getChildrenEntityTypes(payLoad)
        setFieldValues({});
        setFieldMetaData(null);
        setModal({status: !modal.status, type: 'Add'}); 
        toast.success("Entity added successfully");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        toast.error(message);
      }
    },
    err => {
      toast.error("Something went wrong!");
      setUpdatingList(false);
    })
  }

  const editEntity = (data) => {
    setUpdatingList(true);
    props.editEntity(data, res => {
      setUpdatingList(false);
      if(res.status){
        getChildrenEntityTypes(payLoad)
        setFieldValues({});
        setFieldMetaData(null);
        setModal({status: !modal.status, type: 'Edit'}); 
        toast.success("Entity updated successfully");
        setSelectedEntityId(null);
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        toast.error(message);
      }
    },
    err => {
      toast.error("Something went wrong!");
      setUpdatingList(false);
    })
  }

  useEffect(()=>{
    getChildrenEntityTypes(payLoad);
  }, [history.location.pathname]);
  
  const onSubmitForm = (e) => {
    e.preventDefault();
    for (const { field } of fieldMetaData) {
      if (
        field.isRequired === 1 &&
        fieldValues[field.entityAttributeFieldKey] === undefined
      ) {
        toast.error(`${field.fieldName} is required`);
        return;
      }
    }
    if(modal.type==='Add'){
      addEntity({id: selectedEtityTypeId, data: {data: JSON.stringify(fieldValues)}, hash: props.auth.token})
    }else if(modal.type==='Edit'){
      editEntity({id: selectedEntityId, data: {data: JSON.stringify(fieldValues)}, hash: props.auth.token})
    }
  }

  const onClickAdd = () => {
    setModal({status: !modal.status, type: 'Add'});
    setGettingFields(true);
    props.getFormFields(selectedEtityTypeId, res => {
      setGettingFields(false);
      if(res.status){
        setFieldMetaData(res.data);
        setFieldValues(
          res.data.reduce((acc, { field }) => {
            acc[field.entityAttributeFieldKey] = field.fieldValue || null;
            return acc;
          }, {})
        );
      }
      else {
        let message = "Something went wrong!"
        // if(res.message){
        //     message = res.message;
        // }
        toast.error(message);
      }
    },
    err => {
      toast.error("Something went wrong!");
      setGettingFields(false);
    })
  }

  const onClickEdit = (entity) => {
    setModal({status: !modal.status, type: 'Edit'});
    setSelectedEntityId(entity.entityId);
    setGettingFields(true);
    props.getEditFormFields(entity.entityId, res => {
      setGettingFields(false);
      if(res.status){
        setFieldMetaData(res.data);
        setFieldValues(
          res.data.reduce((acc, { field }) => {
            acc[field.entityAttributeFieldKey] = field.fieldValue || null;
            return acc;
          }, {})
        );
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        toast.error(message);
      }
    },
    err => {
      toast.error("Something went wrong!");
      setGettingFields(false);
    })
  }

  const onClickCancel = () => {
    setModal({...modal, status: !modal.status});
    setFieldValues({});
    setFieldMetaData(null);
  }

  const fields = [];
  const renderedFields = {};
  const renderField = (fieldMetaData, parentFieldValue) => {
    if (!Array.isArray(fieldMetaData)) return null;
    fieldMetaData?.map(({ field, childrenFields }, index) => {
      if (
        parentFieldValue !== undefined &&
        renderedFields[field.entityAttributeFieldKey] !== undefined
      ) {
        fields[renderedFields[field.entityAttributeFieldKey]] = (
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (renderedFields[field.entityAttributeFieldKey] === undefined) {
        renderedFields[field.entityAttributeFieldKey] = fields.length;
        fields.push(
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>,
        );
      }

      if (childrenFields) {
        renderField(
          childrenFields[0],
          fieldValues[field.entityAttributeFieldKey],
        );
      }
    });
  };

  const fieldElements = useMemo(() => {
    if (fieldMetaData) {
      renderField(fieldMetaData);
      return fields;
    } else return [];
  }, [fieldMetaData, fieldValues]);

  const childrenEntityType = location.state;
  // const childrenEntityType = props.sidebar && props.sidebar.entityTypes && props.sidebar.entityTypes.length > 0 ? props.sidebar.entityTypes.find(item => item.id == selectedEtityTypeId) : {name: "Children Entity Type"};
  
  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content"> 
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} {childrenEntityType?.name}</h5>
          </div>
          {gettingFields ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
          :
            <form onSubmit={onSubmitForm}>
              <div className="modal-body">
                <div className="row">
                  {fieldElements}
                </div>
              </div>
              <div className="modal-footer">
                {!updatingList && modal.status ? <>
                <button onClick={onClickCancel} type="button" className="cancle_btn_">Cancel</button>
                <button type="submit" name="submit" className="info_btn_">Submit</button></>
                :<span className="spinner-border spinner-border-sm"></span>}
              </div>
            </form>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">{childrenEntityType?.name}</h2>
          <div className="dropdown more-dropdown">
            <button onClick={onClickAdd} className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
                <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
                Add
            </button>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {childrenEntityTypes && childrenEntityTypes.length > 0 ?
                    <>
                      {childrenEntityTypes.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name ? item.name : item.title ? item.title : "N/A"}</td>
                            <td title={item.description}>
                              {item.description ? 
                                item.description.length > 20 ? item.description.substring(0,20) + ' . . .' : item.description
                              : 
                                "N/A"
                              }
                            </td>
                            <td className="table-action">
                                <a onClick={() => {onClickEdit(item)}}>
                                  <i className="fas fa-pencil-alt"></i>
                                </a>
                            </td>
                          </tr>
                        )
                      })}
                        
                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

          <CustomModal
            modalIsOpen={modal.status}
            child={modalContent()}
          />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getChildrenEntityTypes: bindActionCreators(getChildrenEntityTypes, dispatch),
  getFormFields: bindActionCreators(getFormFields, dispatch),
  getEditFormFields: bindActionCreators(getEditFormFields, dispatch),
  getGenesis: bindActionCreators(getGenesis, dispatch),
  getChildrenData: bindActionCreators(getChildrenData, dispatch),
  addEntity: bindActionCreators(addEntity, dispatch),
  editEntity: bindActionCreators(editEntity, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenEntityTypes);
