import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const TypeManagement = () => {
    const history = useHistory();

    useEffect(()=>{
        history.push('/typeManagement/AvailabilityStatus');
    },[])

  return (
    <></>
  )
};

export default TypeManagement;