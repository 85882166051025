import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { activateOpenApplyOption, addOpenApplyOption, deactivateOpenApplyOption, editOpenApplyOption, getOneOpenApplyOption, getOpenApplyOption } from "../../actions/typeManagement/openApplyOption";
import { getOpenApplyQuestion } from "../../actions/typeManagement/openApplyQuestion";
import ReactSelect from "react-select";

const OpenApplyOption = (props) => {

  const initField = {
    "description": "",
    "name": "",
    "openApplyQuestionId": 0,
    "value": ""
  }

  const [loading, setLoading] = useState(false);
  const [isGettingField, setIsGettingField] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [openApplyOption, setOpenApplyOption] = useState([]);
  const [openApplyQuestions, setOpenApplyQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getOpenApplyOption = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getOpenApplyOption({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let data = res.data;
        let sortedData = data && data.length > 0 ? data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setOpenApplyOption(sortedData);
        setFlag(flag);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOpenApplyQuestion = () => {
    props.getOpenApplyQuestion({ flag: 'actives' }, res => {
      if (res.status) {
        let data = res.data;
        let sortedData = data && data.length > 0 ? data.sort((a, b) => a.question.toLowerCase() > b.question.toLowerCase() ? 1 : -1) : [];
        let filteredData = sortedData.filter(a => a.inputDataType == "menuOption" || a.inputDataType == "radioOption")
        setOpenApplyQuestions(filteredData);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
      })
  }

  const addOpenApplyOption = (data) => {
    setUpdatingList(true);
    props.addOpenApplyOption(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getOpenApplyOption(flag);
        setForm(initField);
        setSelectedQuestion(null)
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("Open Apply Option added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const editOpenApplyOption = (data) => {
    setUpdatingList(true);
    props.editOpenApplyOption(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getOpenApplyOption(flag);
        setForm(initField);
        setSelectedQuestion(null)
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("Open Apply Option updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const activateOpenApplyOption = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateOpenApplyOption(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getOpenApplyOption(flag, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateOpenApplyOption = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateOpenApplyOption(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getOpenApplyOption(flag, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneOpenApplyOption = (id) => {
    setIsGettingField(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneOpenApplyOption(id, res => {
      setIsGettingField(false);
      if (res.status) {
        setForm(res.data);
        let selectedOpenApplyQuestion = openApplyQuestions.find((item) => res.data.openApplyQuestionId === item.id);
        setSelectedQuestion(selectedOpenApplyQuestion);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingField(false);
      })
  }

  useEffect(() => {
    getOpenApplyOption(flag);
    getOpenApplyQuestion()
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    } else if (!form.description) {
      toast.error('Please enter description');
      return;
    }
    if (modal.type === 'Add') {
      addOpenApplyOption({ ...form, openApplyQuestionId: selectedQuestion.id });
    } else if (modal.type === 'Edit') {
      editOpenApplyOption({ ...form, openApplyQuestionId: selectedQuestion.id });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
  }

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} Open Apply Option</h5>
          </div>
          {isGettingField && !form.name ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Name" className="form-control entity-form" id="entityname" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.value} onChange={onChangeField} required type="text" name="value" placeholder="Value" className="form-control entity-form" id="entityname" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select Question</label>
                    <ReactSelect
                      isSearchable
                      options={openApplyQuestions}
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedQuestion}
                      onChange={(e) => setSelectedQuestion(e)}
                      placeholder="Question"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.question}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea rows={5} value={form.description} onChange={onChangeField} required type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingList && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inactives":
      filteredBy = "In-Actives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Open Apply Option ({filteredBy})</h2>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getOpenApplyOption('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getOpenApplyOption('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getOpenApplyOption('inactives') }} className={flag === 'inactives' ? "active" : ""}>
                <a className="dropdown-item">In-actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th scope="col">Value</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {openApplyOption && openApplyOption.length > 0 ?
                    <>
                      {openApplyOption.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name ? item.name : "N/A"}</td>
                            <td title={item.description}>
                              {item.description ?
                                item.description.length > 20 ? item.description.substring(0, 20) + ' . . .' : item.description
                                :
                                "N/A"
                              }
                            </td>
                            <td>
                              {switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.active === 1 ? deactivateOpenApplyOption({ id: item.id }) : activateOpenApplyOption({ id: item.id })}
                                  checked={item.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>
                            <td>
                              {item.value ? item.value : "N/A"}
                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneOpenApplyOption(item.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenApplyOption: bindActionCreators(getOpenApplyOption, dispatch),
  getOpenApplyQuestion: bindActionCreators(getOpenApplyQuestion, dispatch),
  editOpenApplyOption: bindActionCreators(editOpenApplyOption, dispatch),
  activateOpenApplyOption: bindActionCreators(activateOpenApplyOption, dispatch),
  deactivateOpenApplyOption: bindActionCreators(deactivateOpenApplyOption, dispatch),
  addOpenApplyOption: bindActionCreators(addOpenApplyOption, dispatch),
  getOneOpenApplyOption: bindActionCreators(getOneOpenApplyOption, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenApplyOption);
