
if (process.env.NODE_ENV === "production") {
  module.exports = {
    API_URL: process.env.REACT_APP_API_URL,
    EXPRESS_MIDDLEWARE_API_URL: process.env.REACT_APP_EXPRESS_MIDDLEWARE_API_URL,
    
    // API_URL: 'https://api.timelyhire.com:8080/rest',
    // EXPRESS_MIDDLEWARE_API_URL: 'https://admin-dev.travelnurses.io:1006'
  };
} else {
  module.exports = {
    API_URL: 'https://api.timelyhire.com:8080/rest',
    EXPRESS_MIDDLEWARE_API_URL: 'http://localhost:5000'
  };
}
