import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { userLogOut } from '../app/actions/user';
const queryString = require('query-string');

/******** Set Authorization token in header ***********/
// export const setAuthorizationToken = (axios, token) => {
//   if (token) {
//     //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//     axios.defaults.headers.common.Authorization = token;
//   } else {
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

// axios.defaults.withCredentials = true;

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, handleErrors);

var config = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Credential': 'same-origin',
  },
};

class RestClient {
  static post(url, params, isAuthRequired = false) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, JSON.stringify(params), config)
        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static put(url, params, isAuthRequired = false) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, JSON.stringify(params), config)
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static patch(url, params, isAuthRequired = false) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .patch(url, JSON.stringify(params), config)
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static delete(url, isAuthRequired = false) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .delete(url, config)
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static get(url, params, isAuthRequired = false) {
    let query = queryString.stringify(params);
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .get(url + '' + query, config)

        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data Method ***********/
  static postFormData(url, params, isAuthRequired = false) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, params, config)

        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static deleteWithData(url, isAuthRequired = false, data = {}) {
    if (isAuthRequired) {
      config = { ...config, withCredentials: true }
    }
    return new Promise(function (fulfill, reject) {
      axios
        .delete(url, { ...config, data: data })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}

function handleErrors(error) {
  var status = error?.response?.status;
  var data = error?.response?.data;
  if (error?.response?.data?.error === "Invalid token supplied with request." || error?.response?.data?.error === "No identity has been assigned to this client") {
    swal({ title: 'Session timed out !', text: 'Please Login to continue.', closeOnClickOutside: false }).then(() => {
      userLogOut();
      window.location.href = "/";
    });
  }
  switch (status) {
    case 401:
      swal({ title: 'Session timed out !', text: 'Please Login to continue.', closeOnClickOutside: false }).then(() => {
        userLogOut();
        window.location.href = "/";
      });
      throw new Error(data.message);
    case 403:
      throw new Error(data.message);
    default:
      break;
  }
  return Promise.reject(error);
}

export default RestClient;
