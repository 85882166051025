import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity Types ********/
export const getScheduleStatus = (params, cb) => {
  let flag = "actives";
  if(params.flag){
    flag = params.flag;
  }

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus/${flag}`)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getOneScheduleStatus = (id, cb) => {

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus/${id}`)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const editScheduleStatus = (params, cb) => {
  return (dispatch) => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus/${params.id}`, params, true)
      .then((result) => {
        // if ((result.status >= 200 && result.status <= 202) && result.data) {
        if (!result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to edit schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addScheduleStatus = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus`, params, true)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          // result = result.data;
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to add schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deactivateScheduleStatus = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus/${params.id}`, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to deactivate schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const activateScheduleStatus = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleStatus/${params.id}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to activate schedule status",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};