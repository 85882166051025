import React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import MobileHeader from './MobileHeader';
import Sidebar from './Sidebar';

const DashboardLayout = (props) => {

    return (
        <>
            {/* <MobileHeader {...props}/> */}
            {/* <!-- Start Desktop View --> */}
            <section className="user-list">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 p-0 m-0">
                            <Header {...props} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="sidebar_overlay"></div>
                        {/* <!-- Start Left Side Section --> */}
                        <Sidebar {...props} />
                        {/* <!-- End Left Side Section --> */}

                        <div className='col-md-12 col-lg-8 col-xl-8 col-xxl-10 p-xl-0 m-xl-0 bg-white'>
                            {/* <Header {...props}/> */}
                            {props.children}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- END Desktop View --> */}
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);