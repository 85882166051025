import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { activateFileMimeType, addFileMimeType, deactivateFileMimeType, editFileMimeType, getOneFileMimeType, getFileMimeType } from "../../actions/typeManagement/fileMimeType";
import { getFileType } from "../../actions/typeManagement/fileType";
import ReactSelect from "react-select";

const FileMimeType = (props) => {

  const initField = {
    "description": "",
    "name": "",
    "fileTypeId": 0,
    "allowedFileExtension": "",
    "fileName": "",
  }

  const [loading, setLoading] = useState(false);
  const [isGettingField, setIsGettingField] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [fileMimeType, setFileMimeType] = useState([]);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);
  const [activeFileTypes, setActiveFileTypes] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState(null);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getFileMimeType = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getFileMimeType({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let data = res.data;
        let sortedData = data && data.length > 0 ? data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setFileMimeType(sortedData);
        setFlag(flag);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const addFileMimeType = (data) => {
    setUpdatingList(true);
    props.addFileMimeType(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getFileMimeType(flag);
        setForm(initField);
        setSelectedFileType(null)
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("FileMime Type added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const editFileMimeType = (data) => {
    setUpdatingList(true);
    props.editFileMimeType(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getFileMimeType(flag);
        setForm(initField);
        setSelectedFileType(null)
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("FileMime Type updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const activateFileMimeType = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateFileMimeType(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getFileMimeType(flag, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateFileMimeType = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateFileMimeType(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getFileMimeType(flag, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneFileMimeType = (id) => {
    setIsGettingField(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneFileMimeType(id, res => {
      setIsGettingField(false);
      if (res.status) {
        setForm(res.data);
        let selectedFileType = activeFileTypes.find((item) => res.data.fileTypeId === item.id);
        setSelectedFileType(selectedFileType);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingField(false);
      })
  }

  const getActiveFileTypes = () => {
    props.getFileType({ flag: 'actives' }, res => {
      if (res.status) {
        let FileTypes = res.data;
        let sortedFileTypes = FileTypes && FileTypes.length > 0 ? FileTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setActiveFileTypes(sortedFileTypes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
      })
  }

  useEffect(() => {
    getFileMimeType(flag);
    getActiveFileTypes()
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    } else if (!form.description) {
      toast.error('Please enter description');
      return;
    }
    if (modal.type === 'Add') {
      addFileMimeType({ ...form, fileTypeId: selectedFileType.id });
    } else if (modal.type === 'Edit') {
      editFileMimeType({ ...form, fileTypeId: selectedFileType.id });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
  }

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} FileMime Type</h5>
          </div>
          {isGettingField && !form.name ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Name" className="form-control entity-form" id="entityname" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea rows={5} value={form.description} onChange={onChangeField} required type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.fileName} onChange={onChangeField} required type="text" name="fileName" placeholder="File Name" className="form-control entity-form" id="fileName" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.allowedFileExtension} onChange={onChangeField} required type="text" name="allowedFileExtension" placeholder="File Extension" className="form-control entity-form" id="allowedFileExtension" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select File Type</label>
                    <ReactSelect
                      isSearchable
                      options={activeFileTypes}
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedFileType}
                      onChange={(e) => setSelectedFileType(e)}
                      placeholder="File Type"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.name}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingList && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inactives":
      filteredBy = "In-Actives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">FileMime Type ({filteredBy})</h2>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getFileMimeType('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getFileMimeType('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getFileMimeType('inactives') }} className={flag === 'inactives' ? "active" : ""}>
                <a className="dropdown-item">In-actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">File Name</th>
                <th scope="col">Allowed Extension</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {fileMimeType && fileMimeType.length > 0 ?
                    <>
                      {fileMimeType.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name ? item.name : "N/A"}</td>
                            <td title={item.description}>
                              {item.description ?
                                item.description.length > 20 ? item.description.substring(0, 20) + ' . . .' : item.description
                                :
                                "N/A"
                              }
                            </td>
                            <td>{item.fileName ? item.fileName : "N/A"}</td>
                            <td>{item.allowedFileExtension ? item.allowedFileExtension : "N/A"}</td>
                            <td>
                              {switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.active === 1 ? deactivateFileMimeType({ id: item.id }) : activateFileMimeType({ id: item.id })}
                                  checked={item.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneFileMimeType(item.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getFileMimeType: bindActionCreators(getFileMimeType, dispatch),
  editFileMimeType: bindActionCreators(editFileMimeType, dispatch),
  activateFileMimeType: bindActionCreators(activateFileMimeType, dispatch),
  deactivateFileMimeType: bindActionCreators(deactivateFileMimeType, dispatch),
  addFileMimeType: bindActionCreators(addFileMimeType, dispatch),
  getOneFileMimeType: bindActionCreators(getOneFileMimeType, dispatch),
  getFileType: bindActionCreators(getFileType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileMimeType);
