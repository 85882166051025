import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity Types ********/
export const getEmailAddressType = (params, cb) => {
  let flag = "actives";
  if (params.flag) {
    flag = params.flag;
  }

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/emailAddressType/${flag}`)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get emailAddress type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getOneEmailAddressType = (id, cb) => {

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/emailAddressType/${id}`)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get emailAddress type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const editEmailAddressType = (params, cb) => {
  return (dispatch) => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/emailAddressType/${params.id}`, params, true)
      .then((result) => {
        // if ((result.status >= 200 && result.status <= 202) && result.data) {
        if (!result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to edit emailAddress type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deactivateEmailAddressType = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/emailAddressType/${params.id}`, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to deactivate emailAddress type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const activateEmailAddressType = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/emailAddressType/${params.id}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to activate emailAddress type",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};