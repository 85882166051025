import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { debounce, getFormatedDate } from "../../../utilities/helpers";
import { activateEntityAttributes, addEntityAttributes, deactivateEntityAttributes, editEntityAttributes, getEntityAttributes, getOneEntityAttribute, getSearchedEntityAttribute } from "../../actions/entityAttribute";
import CustomModal from "../../components/shared/CustomModal";
import { setSidebarData } from "../../../redux/actions/sideBar";

const EntityAttributes = (props) => {

  const initField = {
    "description": "",
    "entityAttributeKey": "",
    "name": "",
  }

  const [loading, setLoading] = useState(false);
  const [isGettingEntityAttribute, setIsGettingEntityAttribute] = useState(false);
  const [updatingEntityAttribute, setUpdatingEntityAttribute] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [entityAttributes, setEntityAttributes] = useState([]);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }


  const getEntityAttributes = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getEntityAttributes({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let entityAttributes = res.data;
        let sortedEntityAttributes = entityAttributes && entityAttributes.length > 0 ? entityAttributes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setEntityAttributes(sortedEntityAttributes);
        setFlag(flag);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getSearchedEntityAttribute = (value) => {
    setLoading(true);
    props.getSearchedEntityAttribute(value, res => {
      setLoading(false);
      if (res.status) {
        let entityAttributes = res.data;
        let sortedEntityAttributes = entityAttributes && entityAttributes.length > 0 ? entityAttributes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setEntityAttributes(sortedEntityAttributes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const addEntityAttributes = (data) => {
    setUpdatingEntityAttribute(true);
    props.addEntityAttributes(data, res => {
      setUpdatingEntityAttribute(false);
      if (res.status) {
        getEntityAttributes(flag);
        setForm(initField);
        saveActiveEntityAttributesToSidebar();
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("Entity attribute added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingEntityAttribute(false);
      })
  }

  const editEntityAttributes = (data) => {
    setUpdatingEntityAttribute(true);
    props.editEntityAttributes(data, res => {
      setUpdatingEntityAttribute(false);
      if (res.status) {
        getEntityAttributes(flag);
        setForm(initField);
        saveActiveEntityAttributesToSidebar();
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("Entity attribute updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingEntityAttribute(false);
      })
  }

  const activateEntityAttributes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateEntityAttributes(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityAttributes(flag, true);
        saveActiveEntityAttributesToSidebar();
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateEntityAttributes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateEntityAttributes(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityAttributes(flag, true);
        saveActiveEntityAttributesToSidebar();
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneEntityAttribute = (id) => {
    setIsGettingEntityAttribute(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneEntityAttribute(id, res => {
      setIsGettingEntityAttribute(false);
      if (res.status) {
        let entityAttribute = res.data;
        setForm({ id: entityAttribute.id, name: entityAttribute.name, description: entityAttribute.description, entityAttributeKey: entityAttribute.entityAttributeKey });
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingEntityAttribute(false);
      })
  }

  const saveActiveEntityAttributesToSidebar = () => {
    props.getEntityAttributes({ flag: 'actives' }, res => {
      if (res.status) {
        let entityAttributes = res.data;
        let sortedEntityAttributes = entityAttributes && entityAttributes.length > 0 ? entityAttributes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        let sidebarMenus = { ...props.sidebar, entityAttributes: sortedEntityAttributes };
        props.setSideBarData(sidebarMenus);
      }
      else {
        if (res.message) {
          toast.error(res.message);
        }
      }
    })
  }


  useEffect(() => {
    getEntityAttributes(flag);
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    } else if (!form.description) {
      toast.error('Please enter description');
      return;
    }
    if (modal.type === 'Add') {
      addEntityAttributes(form);
    } else if (modal.type === 'Edit') {
      editEntityAttributes(form);
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField)
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getSearchedEntityAttribute(value);
    } else {
      getEntityAttributes(flag);
    }
  }

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} Entity Attribute</h5>
          </div>
          {isGettingEntityAttribute ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} type="text" name="name" placeholder="Entity Attribute Name" className="form-control entity-form" id="name" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.entityAttributeKey} onChange={onChangeField} type="text" name="entityAttributeKey" placeholder="Entity Attribute Key" className="form-control entity-form" id="entityAttributeKey" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea rows={5} value={form.description} onChange={onChangeField} type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingEntityAttribute && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="submit" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>}
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Entity Attributes ({filteredBy})</h2>
          <div className="col-md-6">
            <input onChange={optimizedSearch} type="text" placeholder="Search" className="form-control entity-form" />
          </div>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getEntityAttributes('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getEntityAttributes('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {entityAttributes && entityAttributes.length > 0 ?
                    <>
                      {entityAttributes.map((entityAttribute, index) => {
                        return (
                          <tr key={index}>
                            <td title={entityAttribute.name}>{entityAttribute.name ?
                              entityAttribute.name.length > 20 ? entityAttribute.name.substring(0, 20) + ' . . .' : entityAttribute.name
                              :
                              "N/A"
                            }</td>
                            <td title={entityAttribute.description}>
                              {entityAttribute.description ?
                                entityAttribute.description.length > 20 ? entityAttribute.description.substring(0, 20) + ' . . .' : entityAttribute.description
                                :
                                "N/A"
                              }
                            </td>
                            <td>
                              {switchLoading.status && entityAttribute.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => entityAttribute.active === 1 ? deactivateEntityAttributes({ id: entityAttribute.id }) : activateEntityAttributes({ id: entityAttribute.id })}
                                  checked={entityAttribute.active === 1 ? true : false}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>
                            <td>
                              {entityAttribute.createdDate ?
                                <>
                                  {getFormatedDate(entityAttribute.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(entityAttribute.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneEntityAttribute(entityAttribute.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getEntityAttributes: bindActionCreators(getEntityAttributes, dispatch),
  editEntityAttributes: bindActionCreators(editEntityAttributes, dispatch),
  activateEntityAttributes: bindActionCreators(activateEntityAttributes, dispatch),
  deactivateEntityAttributes: bindActionCreators(deactivateEntityAttributes, dispatch),
  addEntityAttributes: bindActionCreators(addEntityAttributes, dispatch),
  getOneEntityAttribute: bindActionCreators(getOneEntityAttribute, dispatch),
  setSideBarData: payload => dispatch(setSidebarData(payload)),
  getSearchedEntityAttribute: bindActionCreators(getSearchedEntityAttribute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityAttributes);
