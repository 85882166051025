import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import { debounce, getFormatedDate } from "../../../utilities/helpers";
import { activateUserProfile, deactivateUserProfile, getSearchedUserProfiles, getUsersDetails, isCredentialCompleted, validateUserProfile, verifyUserProfile } from "../../actions/user/userDetails";

const UsersDetails = (props) => {
  let { subMenuId } = useParams();
  const selectedUserIdHash = subMenuId;
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({status:false, id:null});
  const [switch2Loading, setSwitch2Loading] = useState({status:false, id:null});
  const [switch3Loading, setSwitch3Loading] = useState({status:false, id:null});
  const [switch4Loading, setSwitch4Loading] = useState({status:false, id:null});

  const [flag, setFlag] = useState("actives");
  const [usersDetails, setUsersDetails] = useState([]);
  
  const getUsersDetails = (flag, noLoad) => {
    setFlag(flag);
    if(!noLoad){
      setLoading(true);
    }
    props.getUsersDetails({flag, selectedUserIdHash}, res => {
      setSwitchLoading({status:false, id: null});
      setSwitch2Loading({status:false, id: null});
      setSwitch3Loading({status:false, id: null});
      setSwitch4Loading({status:false, id: null});
      setLoading(false);
      if(res.status){
        setUsersDetails(res.data);
        }
        else {
          let message = "Something went wrong!"
          if(res.message){
              message = res.message;
          }
        //   toast.error(message);
        }
    },
    err => {
      toast.error("Something went wrong!");
      setLoading(false);
    })
  }

  const getSearchedUserProfiles = (value) => {
    setLoading(true);
    props.getSearchedUserProfiles(value, res => {
      setLoading(false);
      if(res.status){
        setUsersDetails(res.data);
        }
        else {
          let message = "Something went wrong!"
          if(res.message){
              message = res.message;
          }
        //   toast.error(message);
        }
    },
    err => {
      toast.error("Something went wrong!");
      setLoading(false);
    })
  }

  const activateUserProfile = (data) => {
    setSwitchLoading({status:true, id: data.userProfileId});
    props.activateUserProfile(data, res => {
      if(res.status){
        getUsersDetails(flag, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({status:false,id: null});
      }
    },
    err => {
      toast.error("Something went wrong!");
      setSwitchLoading({status:false, id: null});
    })
  }

  const deactivateUserProfile = (data) => {
    setSwitchLoading({status:true, id: data.userProfileId});
    props.deactivateUserProfile(data, res => {
      if(res.status){
        getUsersDetails(flag, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({status:false,id: null});
      }
    },
    err => {
      toast.error("Something went wrong!");
      setSwitchLoading({status:false, id: null});
    })
  }

  const isCredentialCompleted = (data) => {
    setSwitch4Loading({status:true, id: data.userProfileId});
    props.isCredentialCompleted(data, res => {
      if(res.status){
        getUsersDetails(flag, true);
        toast.success("Success");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        toast.error(message);
        setSwitch4Loading({status:false ,id: null});
      }
    },
    err => {
      toast.error("Something went wrong!");
      setSwitch4Loading({status:false ,id: null});
    })
  }

  const verifyUserProfile = (data) => {
    setSwitch2Loading({status:true, id: data.userProfileId});
    props.verifyUserProfile(data, res => {
      if(res.status){
        getUsersDetails(flag, true);
        toast.success("Verified successfully");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
          message = res.message;
        }
        toast.error(message);
        setSwitch2Loading({status:false,id: null});
      }
    },
    err => {
      toast.error("Something went wrong!");
      setSwitch2Loading({status:false, id: null});
    })
  }

  const validateUserProfile = (data) => {
    setSwitch3Loading({status:true, id: data.userProfileId});
    props.validateUserProfile(data, res => {
      if(res.status){
        getUsersDetails(flag, true);
        toast.success("Validated successfully");
      }
      else {
        let message = "Something went wrong!"
        if(res.message){
            message = res.message;
        }
        toast.error(message);
        setSwitch3Loading({status:false ,id: null});
      }
    },
    err => {
      toast.error("Something went wrong!");
      setSwitch3Loading({status:false ,id: null});
    })
  }

  useEffect(()=>{
    getUsersDetails(flag);
  }, []);

  const userType = location.state;

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inactives":
      filteredBy = "In-Actives";
      break;
  
    default:
      filteredBy = "All";
      break;
  }

  const handleSearch = (e) => {
    const {value} = e.target;
    if(value){
      getSearchedUserProfiles(value);
    }else{
      getUsersDetails(flag);
    }
  }

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Profiles of {userType?.name} ({filteredBy})</h2>
          <div className="col-md-6">
            <input onChange={optimizedSearch} type="text" placeholder="Search" className="form-control entity-form"/>
          </div>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
                <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
                Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                {/* <a className="dropdown-item" onClick={() => {history.push({pathname:'/userProfile',state:{userTypeId: userType.userTypeId, userIdHash: selectedUserIdHash}})}}>Add</a> */}
              </li>
              <li onClick={() => {getUsersDetails('actives')}} className={flag==='actives'?"active":""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => {getUsersDetails('all')}} className={flag==='all'?"active":""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => {getUsersDetails('inActives')}} className={flag==='inActives'?"active":""}>
                <a className="dropdown-item">In-Actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Verify</th>
                <th scope="col">Validate</th>
                <th scope="col">Credentials complete</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {usersDetails && usersDetails.length > 0 ?
                    <>
                      {usersDetails.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.firstName ? item.firstName : "N/A"}</td>
                            <td>
                              {switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span>:
                              <Switch
                                onChange={() => item.active === 1 ? deactivateUserProfile({userProfileId: item.id}) : activateUserProfile({userProfileId: item.id})}
                                checked={item.active === 1}
                                className="react-switch"
                                onColor="#b7115d"
                              />}
                            
                            </td>
                            <td>
                              {switch2Loading.status && item.id === switch2Loading.id ? <span className="spinner-border spinner-border-sm"></span>:
                              <Switch
                                onChange={() => item.isVerified === 1 ? verifyUserProfile({userProfileId: item.id, value:item.isVerified === 1 ? 0 : 1}) : verifyUserProfile({userProfileId: item.id, value:item.isVerified === 1 ? 0 : 1})}
                                checked={item.isVerified === 1}
                                className="react-switch"
                                onColor="#b7115d"
                              />}
                            
                            </td>
                            <td>
                              {switch3Loading.status && item.id === switch3Loading.id ? <span className="spinner-border spinner-border-sm"></span>:
                              <Switch
                                onChange={() => item.isValidated === 1 ? validateUserProfile({userProfileId: item.id, value:item.isValidated === 1 ? 0 : 1}) : validateUserProfile({userProfileId: item.id, value:item.isValidated === 1 ? 0 : 1})}
                                checked={item.isValidated === 1}
                                className="react-switch"
                                onColor="#b7115d"
                              />}
                            
                            </td>
                            <td>
                              {switch4Loading.status && item.id === switch4Loading.id ? <span className="spinner-border spinner-border-sm"></span>:
                              <Switch
                                onChange={() => item.credentialIsCompleted === 1 ? isCredentialCompleted({userProfileId: item.id,value:item.credentialIsCompleted === 1 ? 0 : 1}) : isCredentialCompleted({userProfileId: item.id, value:item.credentialIsCompleted === 1 ? 0 : 1})}
                                checked={item.credentialIsCompleted === 1}
                                className="react-switch"
                                onColor="#b7115d"
                              />}
                            
                            </td>
                            <td>
                              {item.createdDate ? 
                                <>
                                  {getFormatedDate(item.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(item.createdDate, 'h:mm A')}
                                </>
                                  
                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                                <a onClick={() => {history.push({pathname:'/userProfile',state:{userProfileId: item.id, userIdHash: selectedUserIdHash}})}}>
                                  <i className="fas fa-pencil-alt"></i>
                                </a>
                            </td>
                          </tr>
                        )
                      })}
                        
                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
    getUsersDetails: bindActionCreators(getUsersDetails, dispatch),
    activateUserProfile: bindActionCreators(activateUserProfile, dispatch),
    deactivateUserProfile: bindActionCreators(deactivateUserProfile, dispatch),
    verifyUserProfile: bindActionCreators(verifyUserProfile, dispatch),
    isCredentialCompleted: bindActionCreators(isCredentialCompleted, dispatch),
    validateUserProfile: bindActionCreators(validateUserProfile, dispatch),
    getSearchedUserProfiles: bindActionCreators(getSearchedUserProfiles, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);
