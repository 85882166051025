import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from './redux/store';
import Routes from "./routes";
import { EXPRESS_MIDDLEWARE_API_URL } from "./app/config";
import './app/public/css/custom.css';
import './app/public/css/responsive.css';
import './app/public/css/user-auth.css';

const App = () => {

  // fetching the GET route from the Express server which matches the GET route from server.js
  const callBackendAPI = async () => {
    const response = await fetch(EXPRESS_MIDDLEWARE_API_URL+'/express_backend');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  };

  useEffect(()=>{
    if(process.env.NODE_ENV !== "production"){
      callBackendAPI ();
    }
  },[])


  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </PersistGate>
      </Provider>

      <ToastContainer 
        // position="top-right"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={true}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
    </React.StrictMode>
  );
}

export default App;
