import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { activateEntityTypes, addEntityTypes, deactivateEntityTypes, editEntityTypes, getEntityTypes, getOneEntityType, getSearchedEntityType } from "../../actions/entityType";
import { debounce, getFormatedDate } from "../../../utilities/helpers";
import CustomModal from "../../components/shared/CustomModal";
import Switch from "react-switch";
import { setSidebarData } from "../../../redux/actions/sideBar";
import { useHistory } from "react-router-dom";
import Select from 'react-select';

const EntityTypes = (props) => {

  const initField = {
    "description": "",
    "issueKey": "",
    "name": "",
    "parentEntityTypes": []
  }

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isGettingEntityType, setIsGettingEntityType] = useState(false);
  const [updatingEntityType, setUpdatingEntityType] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [entityTypes, setEntityTypes] = useState([]);
  const [activeEntityTypes, setActiveEntityTypes] = useState([]);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState([]);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const handleChange = (value) => {
    setSelectedDropdownValues(value);
  };

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getEntityTypes = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getEntityTypes({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes = entityTypes && entityTypes.length > 0 ? entityTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setEntityTypes(sortedEntityTypes);
        setFlag(flag);
        if (flag == 'actives') {
          setActiveEntityTypes(sortedEntityTypes);
        }
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getSearchedEntityType = (value) => {
    setLoading(true);
    props.getSearchedEntityType(value, res => {
      setLoading(false);
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes = entityTypes && entityTypes.length > 0 ? entityTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setEntityTypes(sortedEntityTypes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const addEntityTypes = (data) => {
    setUpdatingEntityType(true);
    props.addEntityTypes(data, res => {
      setUpdatingEntityType(false);
      if (res.status) {
        getEntityTypes(flag);
        setForm(initField);
        setSelectedDropdownValues([]);
        saveActiveEntityTypesToSidebar();
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("Entity type added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingEntityType(false);
      })
  }

  const editEntityTypes = (data) => {
    setUpdatingEntityType(true);
    props.editEntityTypes(data, res => {
      setUpdatingEntityType(false);
      if (res.status) {
        getEntityTypes(flag);
        setForm(initField);
        setSelectedDropdownValues([]);
        saveActiveEntityTypesToSidebar();
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("Entity type updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingEntityType(false);
      })
  }

  const activateEntityTypes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateEntityTypes(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityTypes(flag, true);
        saveActiveEntityTypesToSidebar();
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateEntityTypes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateEntityTypes(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityTypes(flag, true);
        saveActiveEntityTypesToSidebar();
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneEntityType = (id) => {
    setActiveEntityTypes(activeEntityTypes.filter(x => x.id !== id))
    setIsGettingEntityType(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneEntityType(id, res => {
      setIsGettingEntityType(false);
      if (res.status) {
        let entityType = res.data;
        setForm(entityType);
        let selected = activeEntityTypes.filter((item) => entityType.parentEntityTypes.some((item2) => item2.id === item.id));
        setSelectedDropdownValues(selected);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingEntityType(false);
      })
  }

  const saveActiveEntityTypesToSidebar = () => {
    props.getEntityTypes({ flag: 'actives' }, res => {
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes = entityTypes && entityTypes.length > 0 ? entityTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        let sidebarMenus = { ...props.sidebar, entityTypes: sortedEntityTypes };
        props.setSideBarData(sidebarMenus);
        setActiveEntityTypes(sortedEntityTypes);
      }
      else {
        if (res.message) {
          toast.error(res.message);
        }
      }
    })
  }

  useEffect(() => {
    getEntityTypes(flag);
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    } else if (!form.description) {
      toast.error('Please enter description');
      return;
    }
    if (modal.type === 'Add') {
      addEntityTypes({ ...form, parentEntityTypes: selectedDropdownValues.map(v => ({ parentEntityTypeId: v.id })) });
    } else if (modal.type === 'Edit') {
      editEntityTypes({ id: form.id, issueKey: form.issueKey ? form.issueKey : "", description: form.description, name: form.name, parentEntityTypes: selectedDropdownValues.map(v => ({ parentEntityTypeId: v.id })) });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
    setSelectedDropdownValues([]);
    saveActiveEntityTypesToSidebar();
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getSearchedEntityType(value);
    } else {
      getEntityTypes(flag);
    }
  }

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} Entity Type</h5>
          </div>
          {isGettingEntityType && !form.name ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Entity Name" className="form-control entity-form" id="entityname" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea rows={5} value={form.description} onChange={onChangeField} required type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.issueKey} onChange={onChangeField} type="text" name="issueKey" placeholder="Issue key" className="form-control entity-form" id="Issue" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select Parent entity types</label>
                    <Select
                      isMulti
                      isSearchable
                      options={activeEntityTypes}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedDropdownValues}
                      onChange={handleChange}
                      placeholder="Parent entity types"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.name}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}

                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingEntityType && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "tops":
      filteredBy = "Tops";
      break;

    case "withPerspectives":
      filteredBy = "With Perspectives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Entity Type <span style={filteredBy === "With Perspectives" ? { "fontSize": 15 } : {}}>({filteredBy})</span></h2>
          <div className="col-md-6">
            <input onChange={optimizedSearch} type="text" placeholder="Search" className="form-control entity-form" />
          </div>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getEntityTypes('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getEntityTypes('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getEntityTypes('tops') }} className={flag === 'tops' ? "active" : ""}>
                <a className="dropdown-item">Tops</a>
              </li>
              <li onClick={() => { getEntityTypes('withPerspectives') }} className={flag === 'withPerspectives' ? "active" : ""}>
                <a className="dropdown-item">With Perspective</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {entityTypes && entityTypes.length > 0 ?
                    <>
                      {entityTypes.map((entityType, index) => {
                        return (
                          <tr key={index}>
                            <td>{entityType.name ? entityType.name : "N/A"}</td>
                            <td title={entityType.description}>
                              {entityType.description ?
                                entityType.description.length > 20 ? entityType.description.substring(0, 20) + ' . . .' : entityType.description
                                :
                                "N/A"
                              }
                            </td>
                            <td>
                              {switchLoading.status && entityType.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => entityType.active === 1 ? deactivateEntityTypes({ id: entityType.id }) : activateEntityTypes({ id: entityType.id })}
                                  checked={entityType.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>
                            <td>
                              {entityType.createdDate ?
                                <>
                                  {getFormatedDate(entityType.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(entityType.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneEntityType(entityType.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                              <a title='Associated Attribute Fields' onClick={() => {
                                history.push({
                                  pathname: `/entityAttribueFields/${entityType.id}`,
                                  state: { name: entityType.name }
                                })
                              }} className="ms-3">
                                <i className="fas fa-link"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getEntityTypes: bindActionCreators(getEntityTypes, dispatch),
  editEntityTypes: bindActionCreators(editEntityTypes, dispatch),
  activateEntityTypes: bindActionCreators(activateEntityTypes, dispatch),
  deactivateEntityTypes: bindActionCreators(deactivateEntityTypes, dispatch),
  addEntityTypes: bindActionCreators(addEntityTypes, dispatch),
  getOneEntityType: bindActionCreators(getOneEntityType, dispatch),
  setSideBarData: payload => dispatch(setSidebarData(payload)),
  getSearchedEntityType: bindActionCreators(getSearchedEntityType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypes);
