import React from "react";
import Modal from "react-modal";

const CustomModal = ({ modalIsOpen, closeModal, child }) => {
  const customStyles = {
    content: {
      width: "50%",
      // top: "50%",
      // left: "50%",
      // right: "auto",
      // bottom: "auto",
      // marginRight: "-50%",
      // transform: "translate(-50%, -50%)",
      // overlfow: "scroll",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {child}
    </Modal>
  );
};

export default CustomModal;
