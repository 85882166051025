import moment from "moment-timezone";

// MMMM Do YYYY, h:mm:ss a
export const getFormatedDate = (date, format = 'MM-DD-YYYY, h:mm:ss A', timeZone = '') => {
    if(date && moment(date).isValid()){
        if(timeZone){
            return moment(date).format(format).tz(timeZone);
        }
        return moment(date).format(format)
    }
    return false;
}

export const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    }
  }