import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { debounce, getFormatedDate } from "../../../utilities/helpers";
import { activatePerspective, addPerspective, deactivatePerspective, editPerspective, getOnePerspective, getPerspective, getSearchedPerspective } from "../../actions/perspective";
import { setSidebarData } from "../../../redux/actions/sideBar";
import ReactSelect from 'react-select';
import Switch from "react-switch";
import CustomModal from "../../components/shared/CustomModal";
import { getEntityTypes } from "../../actions/entityType";
import { useHistory } from "react-router-dom";

const Perspective = (props) => {
  const history = useHistory();

  const initField = {
    "issueKey": "",
    "name": "",
    "parentPerspectives": []
  }

  const [loading, setLoading] = useState(false);
  const [isGettingPerspective, setIsGettingPerspective] = useState(false);
  const [updatingPerspective, setUpdatingPerspective] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [perspective, setPerspective] = useState([]);
  const [activePerspective, setActivePerspective] = useState([]);
  const [activeEntityTypes, setActiveEntityTypes] = useState([]);
  const [selectedParentValues, setSelectedParentValues] = useState([]);
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const handleChange = (value) => {
    setSelectedParentValues(value);
  };

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getPerspective = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getPerspective({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let perspective = res.data;
        let sortedPerspective = perspective && perspective.length > 0 ? perspective.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setPerspective(sortedPerspective);
        setFlag(flag);
        if (flag == 'actives') {
          setActivePerspective(sortedPerspective);
        }
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getSearchedPerspective = (value) => {
    setLoading(true);
    props.getSearchedPerspective(value, res => {
      setLoading(false);
      if (res.status) {
        let perspective = res.data;
        let sortedPerspective = perspective && perspective.length > 0 ? perspective.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setPerspective(sortedPerspective);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const addPerspective = (data) => {
    setUpdatingPerspective(true);
    props.addPerspective(data, res => {
      setUpdatingPerspective(false);
      if (res.status) {
        getPerspective(flag);
        setForm(initField);
        setSelectedParentValues([]);
        setSelectedEntityType(null);
        saveActivePerspectiveToSidebar();
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("Perspective added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingPerspective(false);
      })
  }

  const editPerspective = (data) => {
    setUpdatingPerspective(true);
    props.editPerspective(data, res => {
      setUpdatingPerspective(false);
      if (res.status) {
        getPerspective(flag);
        setForm(initField);
        setSelectedParentValues([]);
        setSelectedEntityType(null);
        saveActivePerspectiveToSidebar();
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("Perspective updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingPerspective(false);
      })
  }

  const activatePerspective = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activatePerspective(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getPerspective(flag, true);
        saveActivePerspectiveToSidebar();
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivatePerspective = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivatePerspective(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getPerspective(flag, true);
        saveActivePerspectiveToSidebar();
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOnePerspective = (id) => {
    setActivePerspective(activePerspective.filter(x => x.id !== id))
    setIsGettingPerspective(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOnePerspective(id, res => {
      setIsGettingPerspective(false);
      if (res.status) {
        let perspective = res.data;
        setForm(perspective);
        let selected = activePerspective.filter((item) => perspective.parentPerspectives.some((item2) => item2.id === item.id));
        let selectedEntity = activeEntityTypes.filter((item) => perspective.entityTypeInfo.some((item2) => item2.id === item.id));
        setSelectedParentValues(selected);
        setSelectedEntityType(selectedEntity[0]);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingPerspective(false);
      })
  }

  const getActiveEntityTypes = () => {
    props.getEntityTypes({ flag: 'actives' }, res => {
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes = entityTypes && entityTypes.length > 0 ? entityTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setActiveEntityTypes(sortedEntityTypes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
      })
  }

  const saveActivePerspectiveToSidebar = () => {
    props.getPerspective({ flag: 'actives' }, res => {
      if (res.status) {
        let perspective = res.data;
        let sortedPerspective = perspective && perspective.length > 0 ? perspective.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        let sidebarMenus = { ...props.sidebar, perspective: sortedPerspective };
        props.setSideBarData(sidebarMenus);
        setActivePerspective(sortedPerspective);
      }
      else {
        if (res.message) {
          toast.error(res.message);
        }
      }
    })
  }

  useEffect(() => {
    getPerspective(flag);
    getActiveEntityTypes();
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    }
    if (!selectedEntityType?.id) {
      toast.error('Please select one entity type');
      return;
    }
    if (modal.type === 'Add') {
      addPerspective({ ...form, entityTypeId: selectedEntityType.id, parentPerspectives: selectedParentValues.map(v => ({ parentPerspectiveId: v.id })) });
    } else if (modal.type === 'Edit') {
      editPerspective({ id: form.id, issueKey: form.issueKey ? form.issueKey : "", entityTypeId: selectedEntityType.id, name: form.name, parentPerspectives: selectedParentValues.map(v => ({ parentPerspectiveId: v.id })) });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
    setSelectedParentValues([]);
    saveActivePerspectiveToSidebar();
    setSelectedEntityType(null);
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getSearchedPerspective(value);
    } else {
      getPerspective(flag);
    }
  }

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} Perspective</h5>
          </div>
          {isGettingPerspective && !form.name ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Perspective Name" className="form-control entity-form" id="perspectivename" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.issueKey} onChange={onChangeField} required type="text" name="issueKey" placeholder="Issue Key" className="form-control entity-form" id="issueKey" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select Entity Type</label>
                    <ReactSelect
                      isSearchable
                      options={activeEntityTypes}
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedEntityType}
                      onChange={(e) => setSelectedEntityType(e)}
                      placeholder="Entity Type"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.name}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select Parent Perspectives</label>
                    <ReactSelect
                      isMulti
                      isSearchable
                      options={activePerspective}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedParentValues}
                      onChange={handleChange}
                      placeholder="Parent Perspectives"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.name}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingPerspective && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Perspective ({filteredBy})</h2>
          <div className="col-md-6">
            <input onChange={optimizedSearch} type="text" placeholder="Search" className="form-control entity-form" />
          </div>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMoremenu">
              <li><a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a></li>
              <li className={flag === 'actives' ? "active" : ""} onClick={() => { getPerspective('actives') }}><a className="dropdown-item">Actives</a></li>
              <li className={flag === 'all' ? "active" : ""} onClick={() => { getPerspective('all') }}><a className="dropdown-item">All</a></li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {perspective && perspective.length > 0 ?
                    <>
                      {perspective.map((perspective, index) => {
                        return (
                          <tr key={index}>
                            <td>{perspective.name ? perspective.name : "N/A"}</td>
                            <td>
                              {switchLoading.status && perspective.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => perspective.active === 1 ? deactivatePerspective({ id: perspective.id }) : activatePerspective({ id: perspective.id })}
                                  checked={perspective.active === 1 ? true : false}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}

                            </td>
                            <td>
                              {perspective.createdDate ?
                                <>
                                  {getFormatedDate(perspective.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(perspective.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                              <a onClick={() => getOnePerspective(perspective.id)}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                              <a title='Associated Attribute Fields' onClick={() => {
                                history.push({
                                  pathname: `/entityAttribueFields/${perspective.id}`,
                                  state: { name: perspective.name, isPrespective: true }
                                })
                              }} className="ms-2">
                                <i className="fas fa-link"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getPerspective: bindActionCreators(getPerspective, dispatch),
  editPerspective: bindActionCreators(editPerspective, dispatch),
  activatePerspective: bindActionCreators(activatePerspective, dispatch),
  deactivatePerspective: bindActionCreators(deactivatePerspective, dispatch),
  addPerspective: bindActionCreators(addPerspective, dispatch),
  getOnePerspective: bindActionCreators(getOnePerspective, dispatch),
  getEntityTypes: bindActionCreators(getEntityTypes, dispatch),
  setSideBarData: payload => dispatch(setSidebarData(payload)),
  getSearchedPerspective: bindActionCreators(getSearchedPerspective, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Perspective);
