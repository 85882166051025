import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { getFormatedDate } from "../../../utilities/helpers";
import { activateUserType, addUserType, deactivateUserType, editUserType, getOneUserType, getUserTypes } from "../../actions/user";
import { setSidebarData } from "../../../redux/actions/sideBar";
import CustomModal from "../../components/shared/CustomModal";
import { useHistory } from "react-router-dom";

const Users = (props) => {
  const history = useHistory();
  const initField = {
    "description": "",
    "name": "",
  }

  const [loading, setLoading] = useState(false);
  const [isGettingField, setIsGettingField] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [flag, setFlag] = useState("actives");
  const [userTypes, setUserTypes] = useState([]);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getUserTypes = (flag, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getUserTypes({ flag: flag }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let userTypes = res.data;
        let sortedUserTypes = userTypes && userTypes.length > 0 ? userTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        setUserTypes(sortedUserTypes);
        setFlag(flag);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
      })
  }

  const addUserTypes = (data) => {
    setUpdatingList(true);
    props.addUserTypes(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getUserTypes(flag);
        setForm(initField);
        saveActiveUserTypesToSidebar();
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("User type added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const editUserTypes = (data) => {
    setUpdatingList(true);
    props.editUserTypes(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getUserTypes(flag);
        setForm(initField);
        saveActiveUserTypesToSidebar();
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("User type updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const activateUserTypes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateUserTypes(data, res => {
      if (res.status) {
        getUserTypes(flag, true);
        saveActiveUserTypesToSidebar();
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateUserTypes = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateUserTypes(data, res => {
      if (res.status) {
        getUserTypes(flag, true);
        saveActiveUserTypesToSidebar();
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneUserType = (id) => {
    setIsGettingField(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneUserType(id, res => {
      setIsGettingField(false);
      if (res.status) {
        setForm(res.data);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setIsGettingField(false);
      })
  }

  const saveActiveUserTypesToSidebar = () => {
    props.getUserTypes({ flag: 'actives' }, res => {
      if (res.status) {
        let userTypes = res.data;
        let sortedUserTypes = userTypes && userTypes.length > 0 ? userTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        let sidebarMenus = { ...props.sidebar, users: sortedUserTypes };
        props.setSideBarData(sidebarMenus);
      }
      else {
        if (res.message) {
          toast.error(res.message);
        }
      }
    })
  }

  useEffect(() => {
    getUserTypes(flag);
  }, [])

  const onSubmitForm = () => {
    if (!form.name) {
      toast.error('Please enter name');
      return;
    } else if (!form.description) {
      toast.error('Please enter description');
      return;
    }
    if (modal.type === 'Add') {
      addUserTypes(form);
    } else if (modal.type === 'Edit') {
      editUserTypes({ id: form.id, description: form.description, name: form.name });
    }
  }

  const onCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
    saveActiveUserTypesToSidebar();
  }

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} User Type</h5>
          </div>
          {isGettingField && !form.name ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.name} onChange={onChangeField} required type="text" name="name" placeholder="Entity Name" className="form-control entity-form" id="entityname" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea rows={5} value={form.description} onChange={onChangeField} required type="text" name="description" placeholder="Description" className="form-control entity-form" id="description" />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingList && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onCancel}>Cancel</button>
                    <button type="button" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  let filteredBy = "All";
  switch (flag) {
    case "actives":
      filteredBy = "Actives";
      break;

    case "all":
      filteredBy = "All";
      break;

    case "inactives":
      filteredBy = "In-Actives";
      break;

    default:
      filteredBy = "All";
      break;
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">User Type ({filteredBy})</h2>
          <div className="dropdown more-dropdown">
            <button className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Filter
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMoremenu">
              <li>
                <a className="dropdown-item" onClick={() => { setModal({ status: !modal.status, type: 'Add' }) }}>Add</a>
              </li>
              <li onClick={() => { getUserTypes('actives') }} className={flag === 'actives' ? "active" : ""}>
                <a className="dropdown-item">Actives</a>
              </li>
              <li onClick={() => { getUserTypes('all') }} className={flag === 'all' ? "active" : ""}>
                <a className="dropdown-item">All</a>
              </li>
              <li onClick={() => { getUserTypes('inactives') }} className={flag === 'inactives' ? "active" : ""}>
                <a className="dropdown-item">In-actives</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {userTypes && userTypes.length > 0 ?
                    <>
                      {userTypes.map((userType, index) => {
                        return (
                          <tr key={index}>
                            <td>{userType.name ? userType.name : "N/A"}</td>
                            <td title={userType.description}>
                              {userType.description ?
                                userType.description.length > 20 ? userType.description.substring(0, 20) + ' . . .' : userType.description
                                :
                                "N/A"
                              }
                            </td>
                            <td>
                              {switchLoading.status && userType.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => userType.active === 1 ? deactivateUserTypes({ id: userType.id }) : activateUserTypes({ id: userType.id })}
                                  checked={userType.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                />}
                            </td>
                            <td>
                              {userType.createdDate ?
                                <>
                                  {getFormatedDate(userType.createdDate, 'MM-DD-YYYY')}
                                  <br></br>
                                  {getFormatedDate(userType.createdDate, 'h:mm A')}
                                </>

                                : "N/A"
                              }
                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneUserType(userType.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                              <div class="btns_ mx-3">
                                <button class="update_" onClick={() => {
                                  history.push({
                                    pathname: `/typeManagement/UserTypeCategory/${userType.id}`,
                                    state: { name: userType.name }
                                  })
                                }}>Category</button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getUserTypes: bindActionCreators(getUserTypes, dispatch),
  editUserTypes: bindActionCreators(editUserType, dispatch),
  activateUserTypes: bindActionCreators(activateUserType, dispatch),
  deactivateUserTypes: bindActionCreators(deactivateUserType, dispatch),
  addUserTypes: bindActionCreators(addUserType, dispatch),
  getOneUserType: bindActionCreators(getOneUserType, dispatch),
  setSideBarData: payload => dispatch(setSidebarData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
