import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { activateAttributeField, addAttributeField, deactivateAttributeField, editAttributeField, getEntityAttributeFields, getOneAttributeField } from "../../actions/entityType/attributeFields";
import { getEntityAttributes } from "../../actions/entityAttribute";
import { MenuItem, Select } from "@mui/material";
import Switch from "react-switch";
import ReactSelect from "react-select";

const dataTypes = [
  { value: 'boolean', label: 'Boolean' },
  { value: 'integer', label: 'Integer' },
  { value: 'decimal', label: 'Decimal' },
  { value: 'text', label: 'Text' },
  { value: 'textarea', label: 'Textarea' },
  { value: 'datetime', label: 'Datetime' },
  { value: 'file', label: 'File' },
  { value: 'genesis', label: 'Genesis' },
]

const EntityAttribueFields = (props) => {
  let { subMenuId } = useParams();
  const location = useLocation();
  const selectedEtityTypeId = subMenuId;
  const isPrespective = location?.state?.isPrespective;

  const initField = {
    "entityAttributeFieldKey": "",
    "entityAttributeId": "",
    "fieldDataType": "",
    "isRequired": 0,
    "customFieldName": "",
    "maxLength": 0,
    "minLength": 0,
    "regex": "",
    "regexJs": ""
  }

  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({ status: false, id: null });
  const [gettingFields, setGettingFields] = useState(false);
  const [updatingList, setUpdatingList] = useState(false);
  const [attributeFields, setAttributeFields] = useState([]);
  const [activeAttributes, setActiveAttributes] = useState([]);
  const [selectedEntityAttribute, setSelectedEntityAttribute] = useState(null);
  const [modal, setModal] = useState({ status: false, type: 'Add' });
  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value.trimStart() })
  }

  const getEntityAttributeFields = (selectedEtityType, noLoad) => {
    if (!noLoad) {
      setLoading(true);
    }
    props.getEntityAttributeFields({ id: selectedEtityType, isPrespective }, res => {
      setSwitchLoading({ status: false, id: null });
      setLoading(false);
      if (res.status) {
        let entityTypes = res.data;
        let sortedEntityTypes = entityTypes && entityTypes.length > 0 ? entityTypes.sort((a, b) => a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1) : [];
        setAttributeFields(sortedEntityTypes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        setSwitchLoading({ status: false, id: null });
        // toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setLoading(false);
        setSwitchLoading({ status: false, id: null });
      })
  }

  const addAttributeField = (data) => {
    setUpdatingList(true);
    props.addAttributeField(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getEntityAttributeFields(selectedEtityTypeId);
        setForm(initField);
        setSelectedEntityAttribute(null);
        setModal({ status: !modal.status, type: 'Add' });
        toast.success("Field added successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const editAttributeField = (data) => {
    setUpdatingList(true);
    props.editAttributeField(data, res => {
      setUpdatingList(false);
      if (res.status) {
        getEntityAttributeFields(selectedEtityTypeId);
        setForm(initField);
        setSelectedEntityAttribute(null);
        setModal({ status: !modal.status, type: 'Edit' });
        toast.success("Perspective updated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setUpdatingList(false);
      })
  }

  const activateAttributeField = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.activateAttributeField(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityAttributeFields(selectedEtityTypeId, true);
        toast.success("Activated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const deactivateAttributeField = (data) => {
    setSwitchLoading({ status: true, id: data.id });
    props.deactivateAttributeField(data, res => {
      setSwitchLoading({ status: false, id: null });
      if (res.status) {
        getEntityAttributeFields(selectedEtityTypeId, true);
        toast.success("Deactivated successfully");
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
        setSwitchLoading({ status: false, id: null });
      }
    },
      err => {
        toast.error("Something went wrong!");
        setSwitchLoading({ status: false, id: null });
      })
  }

  const getOneAttributeField = (id) => {
    setGettingFields(true);
    setModal({ status: !modal.status, type: 'Edit' });
    props.getOneAttributeField(id, res => {
      setGettingFields(false);
      if (res.status) {
        let attributes = res.data;
        setForm(attributes);
        let selected = activeAttributes.find((item) => item.id === attributes.entityAttributeId);
        setSelectedEntityAttribute(selected);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setGettingFields(false);
      })
  }

  const getEntityAttributes = () => {
    setGettingFields(true);
    props.getEntityAttributes({ flag: 'actives' }, res => {
      setGettingFields(false);
      if (res.status) {
        let entityAttributes = res.data;
        let sortedEntityAttributes = entityAttributes && entityAttributes.length > 0 ? entityAttributes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
        let genesisExcludedEntityAttributes = sortedEntityAttributes.filter((item) => !item.systemTag.includes("genesis"));
        setActiveAttributes(genesisExcludedEntityAttributes);
      }
      else {
        let message = "Something went wrong!"
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
      err => {
        toast.error("Something went wrong!");
        setGettingFields(false);
      })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    getEntityAttributeFields(selectedEtityTypeId);
    getEntityAttributes();
  }, []);

  const onSubmitForm = () => {
    if (modal.type === 'Add') {
      addAttributeField({
        ...form,
        entityAttributeId: selectedEntityAttribute.id,
        [isPrespective ? "perspectiveId" : "entityTypeId"]: parseInt(selectedEtityTypeId),
        maxLength: parseInt(form.maxLength),
        minLength: parseInt(form.minLength),
      })
    } else if (modal.type === 'Edit') {
      editAttributeField({
        id: form.id,
        entityAttributeFieldKey: form.entityAttributeFieldKey,
        entityAttributeId: selectedEntityAttribute.id,
        fieldDataType: form.fieldDataType,
        isRequired: form.isRequired,
        regex: form.regex,
        regexJs: form.regexJs,
        customFieldName: form.customFieldName,
        [isPrespective ? "perspectiveId" : "entityTypeId"]: parseInt(selectedEtityTypeId),
        maxLength: parseInt(form.maxLength),
        minLength: parseInt(form.minLength),
      })
    }
  }

  const onClickAdd = () => {
    setModal({ status: !modal.status, type: 'Add' });
  }

  const onClickCancel = () => {
    setModal({ ...modal, status: !modal.status });
    setForm(initField);
    setSelectedEntityAttribute(null);
  }

  const childrenEntityType = location.state;

  const modalContent = () => {
    return (
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mx-auto" id="formModalLabel">{modal.type} {childrenEntityType?.name}</h5>
          </div>
          {gettingFields ?
            <div className="text-center m-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input value={form.customFieldName} onChange={onChangeField} type="text" name="customFieldName" placeholder="Custom Field Name" className="form-control entity-form" id="customFieldName" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select entity attribute</label>
                    <ReactSelect
                      isSearchable
                      options={activeAttributes}
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedEntityAttribute}
                      onChange={(e) => { setSelectedEntityAttribute(e); setForm({ ...form, entityAttributeFieldKey: e.entityAttributeKey }) }}
                      placeholder="Entity Attribute"
                      getOptionValue={(option) => `${option['id']}`}
                      getOptionLabel={(option) => `${option.name}`}
                      styles={{ input: (provided) => ({ ...provided, paddingTop: 10, paddingBottom: 10 }) }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input disabled value={form.entityAttributeFieldKey} onChange={onChangeField} type="text" name="entityAttributeFieldKey" placeholder="Entity Attribute FieldKey" className="form-control entity-form" id="entityAttributeFieldKey" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Select Field Data Type</label>
                    <Select
                      style={{ padding: 0 }}
                      value={form.fieldDataType}
                      onChange={(e) => setForm({ ...form, fieldDataType: e.target.value })}
                      className="form-select entity-form"
                      placeholder="Select Entity Type"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        }
                      }}
                    >
                      {dataTypes.map((value) => (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                        >
                          {value.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Minimum Length</label>
                    <input value={form.minLength} onChange={onChangeField} type="number" name="minLength" placeholder="Minimum Length" className="form-control entity-form" id="minLength" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="label">Maximum Length</label>
                    <input value={form.maxLength} onChange={onChangeField} type="number" name="maxLength" placeholder="Maximum Length" className="form-control entity-form" id="maxLength" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.regex} onChange={onChangeField} type="text" name="regex" placeholder="Regex" className="form-control entity-form" id="regex" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <input value={form.regexJs} onChange={onChangeField} type="text" name="regexJs" placeholder="Regex Js" className="form-control entity-form" id="regexJs" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <input type="checkbox" checked={form.isRequired == 1} onChange={(e) => setForm({ ...form, isRequired: form.isRequired === 1 ? 0 : 1 })} className="form-check-input mr-3" id="checkbox" />
                      <label for="checkbox" className="checbox_text_">Is Required?</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {!updatingList && modal.status ?
                  <div className="mt-2">
                    <button type="button" className="cancle_btn_" onClick={onClickCancel}>Cancel</button>
                    <button type="submit" name="submit" className="info_btn_" onClick={onSubmitForm}>Submit</button>
                  </div>
                  :
                  <span className="spinner-border spinner-border-sm"></span>}
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="content_area me-0 me-xxl-5">
        <div className="header-title">
          <h2 className="heading-text">Attribute Fields of {childrenEntityType?.name}</h2>
          <div className="dropdown more-dropdown">
            <button onClick={onClickAdd} className="dropdown-toggle" type="button" id="dropdownMoremenu" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="dots" width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.44445 4.88893C1.09441 4.88893 -1.19209e-05 3.7945 -1.19209e-05 2.44446C-1.19209e-05 1.09442 1.09441 0 2.44445 0C3.79449 0 4.88892 1.09442 4.88892 2.44446C4.88892 3.7945 3.79449 4.88893 2.44445 4.88893Z" fill="#b7115d"></path><path d="M2.44445 13.4445C1.09441 13.4445 -1.19209e-05 12.35 -1.19209e-05 11C-1.19209e-05 9.64997 1.09441 8.55554 2.44445 8.55554C3.79449 8.55554 4.88892 9.64997 4.88892 11C4.88892 12.35 3.79449 13.4445 2.44445 13.4445Z" fill="#b7115d"></path><path d="M2.44445 22C1.09441 22 -1.19209e-05 20.9056 -1.19209e-05 19.5555C-1.19209e-05 18.2055 1.09441 17.1111 2.44445 17.1111C3.79449 17.1111 4.88892 18.2055 4.88892 19.5555C4.88892 20.9056 3.79449 22 2.44445 22Z" fill="#b7115d"></path></svg>
              Add
            </button>
          </div>
        </div>
        {/* <!-- Start Tables --> */}
        <div className="timelyHire-tables table-responsive">
          <table className="table registrations_table">
            <thead>
              <tr role="row">
                <th scope="col">Field Name</th>
                <th scope="col">Entity Attribute Id</th>
                <th scope="col">Field Data Type</th>
                <th scope="col">Genesis Id</th>
                <th scope="col">Parent Id</th>
                <th scope="col">Required</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ?
                <>
                  {attributeFields && attributeFields.length > 0 ?
                    <>
                      {attributeFields.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td title={item.fieldName}>{item.fieldName ? item.fieldName.length > 20 ? item.fieldName.substring(0, 20) + ' . . .' : item.fieldName : "N/A"}</td>
                            <td>{item.entityAttributeId ? item.entityAttributeId : "N/A"}</td>
                            <td>
                              {item.fieldDataType ? item.fieldDataType : "N/A"}
                            </td>
                            <td>{item.genesisId !== 0 ? item.genesisId : "N/A"}</td>
                            <td>{item.parentId !== 0 ? item.parentId : "N/A"}</td>
                            <td>{item.isRequired ? <i className="fas fa-check"></i> : ""}</td>
                            <td>
                              {item.fieldDataType !== "genesis" ? switchLoading.status && item.id === switchLoading.id ? <span className="spinner-border spinner-border-sm"></span> :
                                <Switch
                                  onChange={() => item.active === 1 ? deactivateAttributeField({ id: item.id }) : activateAttributeField({ id: item.id })}
                                  checked={item.active === 1}
                                  className="react-switch"
                                  onColor="#b7115d"
                                /> : "N/A"}

                            </td>
                            <td className="table-action">
                              <a onClick={() => { getOneAttributeField(item.id) }}>
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}

                    </>
                    :
                    <tr className="text-center"><td colSpan={5} className="p-5">No Record(s) Found</td></tr>
                  }
                </>
                :
                <tr className="text-center">
                  <td colSpan={5} className="p-5">
                    <span className="spinner-border spinner-border-sm mt-5 p-2"></span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <CustomModal
          modalIsOpen={modal.status}
          child={modalContent()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  getEntityAttributeFields: bindActionCreators(getEntityAttributeFields, dispatch),
  getEntityAttributes: bindActionCreators(getEntityAttributes, dispatch),
  editAttributeField: bindActionCreators(editAttributeField, dispatch),
  activateAttributeField: bindActionCreators(activateAttributeField, dispatch),
  deactivateAttributeField: bindActionCreators(deactivateAttributeField, dispatch),
  addAttributeField: bindActionCreators(addAttributeField, dispatch),
  getOneAttributeField: bindActionCreators(getOneAttributeField, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityAttribueFields);
