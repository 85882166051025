import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity Types ********/
export const getUsersDetails = (params, cb) => {
  let flag = "actives";
  if(params.flag){
    flag = params.flag;
  }

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile/${flag}?userIdHash=${params.selectedUserIdHash}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get users details",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addUserProfile = (params, cb) => {
  console.log(params)

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/${params.userTypeId}?userIdHash=${params.userIdHash}`, params.data, true)
      .then((result) => {
        console.log(result)
        if (result.status === 200) {
          // result = result.data;
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to add user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deactivateUserProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile?userProfileId=${params.userProfileId}`, true)
      .then((result) => {
        console.log(result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to deactivate user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const activateUserProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile/${params.userProfileId}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to activate user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const isCredentialCompleted = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile/isCredentialCompleted/${params.userProfileId}?value=${params.value}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to update user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const validateUserProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile/validation/${params.userProfileId}?value=${params.value}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to update user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const verifyUserProfile = (params, cb) => {
    return (dispatch) => {
      RestClient.patch(`${EXPRESS_MIDDLEWARE_API_URL}/userProfile/verification/${params.userProfileId}?value=${params.value}`, {}, true)
        .then((result) => {
          if (result) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : "Unable to update user profile",
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const editUserProfile = (params, cb) => {
  console.log(params);
  return (dispatch) => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/${params.userProfileId}`, params, true)
      .then((result) => {
        // if ((result.status >= 200 && result.status <= 202) && result.data) {
        if (!result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to update user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getOneUserProfile = (id, cb) => {

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/userProfile/${id}`)
      .then((result) => {
        if ((result.status >= 200 && result.status <= 202) && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get user profile",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSearchedUserProfiles = (params, cb) => {

  return (dispatch) => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/admin/searchUserProfile?search=${params}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get users profile list",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};