import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

/****** action creator for Entity Types ********/
export const getEntityTypesParent = (params, cb) => {

  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityType/${params}/parent`)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to get parent entity types",
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getEntityTypesChildren = (params, cb) => {

    return (dispatch) => {
      RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityType/${params}/children`)
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : "Unable to get children entity types",
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

  export const getEntityTypesPrespective = (params, cb) => {

    return (dispatch) => {
      RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/perspective/${params}/entityType`)
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : "Unable to get entity types with prespectives",
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };