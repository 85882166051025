const mainMenus = {
  typeManagement: 'Type Management',
  entityTypes: 'Entity Types',
  perspective: 'Perspective',
  entityAttributes: 'Entity Attributes',
  users: 'Users'
};

const subMenus = {
  typeManagement: [
    { name: 'Schedule Type', link: 'ScheduleType' },
    { name: 'Shift Type', link: 'ShiftType' },
    { name: 'Employment Type', link: 'EmploymentType' },
    { name: 'Payroll Type', link: 'PayrollType' },
    { name: 'Pay Scale Type', link: 'PayScaleType' },
    { name: 'Pay Currency Type', link: 'PayCurrencyType' },
    { name: 'Facility Type', link: 'FacilityType' },
    { name: 'Availability Status', link: 'AvailabilityStatus' },
    { name: 'Booking Status', link: 'BookingStatus' },
    { name: 'Schedule Status', link: 'ScheduleStatus' },
    { name: 'User Status', link: 'UserStatus' },
    { name: 'User Type', link: 'UserType' },
    { name: 'Space Type', link: 'SpaceType' },
    { name: 'User Device Type', link: 'UserDeviceType' },
    { name: 'Contact Us Priority Type', link: 'ContactUsPriorityType' },
    { name: 'Contact Us Type', link: 'ContactUsType' },
    { name: 'Notification Priority Type', link: 'NotificationPriorityType' },
    { name: 'Notification Type', link: 'NotificationType' },
    { name: 'Service Term Type', link: 'ServiceTermType' },
    { name: 'EcommServiceKey', link: 'EcommServiceKey' },
    { name: 'Service Term', link: 'ServiceTerm' },
    { name: 'Email Address Type', link: 'EmailAddressType' },
    { name: 'File Type', link: 'FileType' },
    { name: 'File Type Mime', link: 'FileTypeMime' },
    { name: 'File', link: 'File' },
    { name: 'Page Content Type', link: 'PageContentType' },
    { name: 'Page Content', link: 'PageContent' },
    { name: 'Open Apply Question', link: 'OpenApplyQuestion' },
    { name: 'Open Apply Option', link: 'OpenApplyOption' },
  ],
  entityTypes: [],
  perspective: [],
  entityAttributes: [],
  users: []
};

export {
  mainMenus,
  subMenus
};